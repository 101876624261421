import React from "react";
import "./Plans.css";

const Plans = () => {
  const PurchasePage = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <div className="plans-container">
        <div className="plans-free-div">
          <p className="plans-free-div-headline">Free</p>
          <p className="plans-free-div-price">$0</p>
          <p className="plans-free-div-price-description">3 free monitors for team members or services</p>
          <img src="https://signaltuner.com/images/SignalTUNER%20Logo-01-circle.png" style={{ width: "70%"}} alt="SignalTUNER Logo" />
        </div>
        <div className="plans-starter-div">
          <p className="plans-starter-div-headline">Starter</p>
          <p className="plans-starter-div-price">$10 per monitor</p>
          <p className="plans-starter-div-price-description">subscribing to +1 monitor at a time / $10 per monitor monthly</p>
          <button className="plans-starter-div-button" onClick={() => PurchasePage("https://buy.stripe.com/test_8wM29Q66lbA47VC8wH")}>
            Get it now
          </button>
          <p className="plans-starter-div-advantage">Good for small teams of less than 5</p>
        </div>
        <div className="plans-professional-div">
          <p className="plans-professional-div-headline">Professional</p>
          <p className="plans-professional-div-price">$5 per monitor</p>
          <p className="plans-professional-div-price-description">subscribing to +10 monitors at a time / $50 per bundle monthly</p>
          <button className="plans-professional-div-button" onClick={() => PurchasePage("https://buy.stripe.com/test_28obKqgKZaw08ZG5kt")}>
            Get it now
          </button>
          <p className="plans-professional-div-advantage">Save $20 per month per bundle over the Starter plan</p>
        </div>
        <div className="plans-business-div">
          <p className="plans-business-div-headline">Business</p>
          <p className="plans-business-div-price">$4 per monitor</p>
          <p className="plans-business-div-price-description">subscribing to +25 monitors at a time / $100 per bundle monthly</p>
          <button className="plans-business-div-button" onClick={() => PurchasePage("https://buy.stripe.com/test_fZe29QamB47CcbS4gq")}>
            Get it now
          </button>
          <p className="plans-business-div-advantage">Save $50 per month per bundle over the Professional plan</p>
        </div>
      </div>
    </div>
  );
};

export default Plans;
