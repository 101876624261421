import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import axios from "axios";
import config from "./config";
import {
  AddTeamMemberCircleSvg,
  AddTeamMemberIconSvg,
  InviteButtonIconSvg,
  OuterEllipseSvg,
  InnerEllipseSvg,
  DetailsCloseButtonSvg,
  DetailsIconBackgroundSvg,
  DetailsIconWorkspaceSvg,
  DetailsIconNetworkSvg,
  DetailsIconDeviceSvg,
} from "./images/TeamSVG.js";
import {
  RemoveServiceButtonSvg,
  ServiceDetailsButtonIconSvg,
  ServiceDetailsButtonArrowSvg,
  MonitorButtonArrowSvg,
  NoIssueStatusIconSvg,
  IncidentStatusIconSvg,
  OutageStatusIconSvg,
} from "./images/ServicesSVG";
import { Gauge100, Gauge095, Gauge090, Gauge085, Gauge080, Gauge075, Gauge070, Gauge065, Gauge060, Gauge055, Gauge050, Gauge045 } from "./images/Gauges";
import {
  CarouselButtonLeftSVG,
  CarouselButtonRightSVG,
  AddServiceCircleSvg,
  AddServiceIconSvg,
  InviteIconSvg,
  InviteIconAiplaneSvg,
  AddServicesIconSvg,
  AddMembersIconSvg,
} from "./images/DashboardSVG";

const Dashboard = ({ loggedInEmail, activationCode, signal, logoutClick, team, isAdmin, userID, teamServices }) => {
  const [visibleItemsCount, setVisibleItemsCount] = useState(6);
  const [visibleStartIndexTeam, setVisibleStartIndexTeam] = useState(0);
  const [visibleStartIndexServices, setVisibleStartIndexServices] = useState(0);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [shareEmail, setShareEmail] = useState("");

  const navigate = useNavigate();

  const API_URL = config.API_URL;

  const handleShareInputChange = (e) => {
    setShareEmail(e.target.value);
  };

  const handleShareButtonClick = async () => {
    if (!shareEmail || !shareEmail.includes("@")) {
      alert("The email is not valid. Please enter a valid email address.");
      return; // Stop the function if the email is invalid
    }

    try {
      const response = await fetch(`${API_URL}api/User/SendRecommendation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Email: shareEmail }), // Match the EmailRequest class on the backend
      });

      if (response.ok) {
        const result = await response.json();
        setShareEmail('');
        alert("Recommendation email sent successfully!");
      } else {
        alert("Failed to send the recommendation email.");
      }
    } catch (error) {
      console.error("Error sending recommendation email:", error);
      alert("An error occurred while sending the recommendation email.");
    }
  };

  const handleTeamMoreDetailsClick = (memberId) => {
    navigate(`/team/${memberId}`);
  };

  const handleMySignalMoreDetailsClick = () => {
    navigate("/signal");
  };

  const handleServiceDetailsClick = (serviceName, description) => {
    // Construct the URL with query parameters
    const url = `/services?serviceName=${encodeURIComponent(serviceName)}&description=${encodeURIComponent(description)}`;

    // Use navigate to navigate to the Services.js page with the selected service details
    navigate(url, { replace: true });
  };

  const handleAddServicesButtonClick = () => {
    navigate("/services");
  };

  const handleAddMembersButtonClick = () => {
    navigate("/team", { state: { openInviteModal: true } });
  };

  const handlePreviousTeam = () => {
    const lastIndex = team.length - 1;

    if (visibleStartIndexTeam === 0) {
      setVisibleStartIndexTeam(lastIndex); // Loop to the last service
    } else {
      setVisibleStartIndexTeam((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextTeam = () => {
    const lastIndex = team.length - 1;

    if (visibleStartIndexTeam === lastIndex) {
      setVisibleStartIndexTeam(0); // Loop to the first service
    } else {
      setVisibleStartIndexTeam((prevIndex) => prevIndex + 1);
    }
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case "No issues":
        return (
          <div className="status-icon" style={{ background: "#3BB537", width: "18px", height: "18px" }}>
            <NoIssueStatusIconSvg />
          </div>
        );
      case "Incident":
        return (
          <div className="status-icon" style={{ background: "#F9AE00", width: "18px", height: "18px" }}>
            <IncidentStatusIconSvg />
          </div>
        );
      case "Outage":
        return (
          <div className="status-icon" style={{ background: "#EC2F3E", width: "18px", height: "18px" }}>
            <OutageStatusIconSvg />
          </div>
        );
      default:
        return null;
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "No issues":
        return { color: "#3BB537" };
      case "Incident":
        return { color: "#F9AE00" };
      case "Outage":
        return { color: "#EC2F3E" };
      default:
        return {};
    }
  };

  const resizeHandler = () => {
    const viewportWidth = window.innerWidth;
    let visibleItems;

    if (viewportWidth < 600) {
      // Example breakpoint
      visibleItems = 2;
    } else if (viewportWidth < 900) {
      // Another breakpoint
      visibleItems = 4;
    } else {
      visibleItems = 6;
    }

    // Update state to change the number of visible items
    setVisibleItemsCount(visibleItems);
  };

  const gaugeComponents = {
    100: Gauge100,
    95: Gauge095,
    90: Gauge090,
    85: Gauge085,
    80: Gauge080,
    75: Gauge075,
    70: Gauge070,
    65: Gauge065,
    60: Gauge060,
    55: Gauge055,
    50: Gauge050,
    45: Gauge045,
  };

  const roundUpToNearestFive = (num) => {
    return Math.ceil(num / 5) * 5;
  };

  const getGaugeComponent = (score) => {
    const roundedScore = roundUpToNearestFive(score);
    const GaugeComponent = gaugeComponents[roundedScore];

    if (!GaugeComponent) {
      return null;
    }

    return <GaugeComponent className="gauge-svg" />;
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    // Initial resize call
    resizeHandler();

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  useEffect(() => {
    const fetchTeamServiceSelections = async () => {
      try {
        setLoading(true);
        const userID = localStorage.getItem("userID"); // Assuming userID is stored in localStorage
        const token = localStorage.getItem("token"); // Assuming token is stored in localStorage

        const response = await axios.get(`${API_URL}api/User/GetAllServicesWithSelectionStatus/${userID}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setServices(teamServices);
        setError("");
      } catch (err) {
        setError("Failed to fetch service selections");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamServiceSelections();
  }, [teamServices]);

  const selectedServices = services.filter((service) => service.IsSelected);

  const getServiceAtIndex = (index) => {
    // Safeguard in case selectedServices is empty or shorter than expected
    if (selectedServices.length === 0) return null;
    return selectedServices[index % selectedServices.length];
  };

  const handlePreviousService = () => {
    const lastIndex = selectedServices.length - 1;

    if (visibleStartIndexServices === 0) {
      setVisibleStartIndexServices(lastIndex); // Loop to the last service
    } else {
      setVisibleStartIndexServices((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextService = () => {
    const lastIndex = selectedServices.length - 1;

    if (visibleStartIndexServices === lastIndex) {
      setVisibleStartIndexServices(0); // Loop to the first service
    } else {
      setVisibleStartIndexServices((prevIndex) => prevIndex + 1);
    }
  };

  if (!signal) {
    console.log("Could not retrieve signal data.");
    return <div>Loading signal data...</div>;
  }

  return (
    <div>
      <div className="dashboard-team-label-container">
        <h2 className="dashboard-my-signal-label">My Signal</h2>
        {team && team.length > 0 ? <h2 className="dashboard-team-name">{team[0].TeamName}</h2> : <h2 className="dashboard-team-name">Loading team data...</h2>}
        <button className="dashboard-add-team-members-button" onClick={handleAddMembersButtonClick}>
          <AddMembersIconSvg />
          Add members
        </button>
      </div>
      <div className="dashboard-team-carousel-container">
        <button className="dashboard-team-carousel-button left" onClick={handlePreviousTeam}>
          <CarouselButtonLeftSVG className="carousel-arrow" />
        </button>
        <div className="dashboard-team-carousel">
          {team &&
            (() => {
              const userIdFromStorage = localStorage.getItem("userID");
              const numericUserIdFromStorage = parseInt(userIdFromStorage, 10);
              const filteredTeam = team.filter((member) => member.UserId !== numericUserIdFromStorage);
              const totalCards = Math.max(filteredTeam.length + 1, 5);

              // Create an array of 5 elements, filling with team members first, then 'Add' cards
              return Array.from({ length: totalCards }).map((_, index) => {
                // Special card for the first position
                if (index === 0) {
                  // Assuming you have a 'signal' object and 'userFirstName', 'userLastName' in local storage
                  const signalScore = signal.SignalScore;
                  const userFirstName = localStorage.getItem("userFirstName");
                  const userLastName = localStorage.getItem("userLastName");

                  return (
                    <div key={`special-${index}`} className="dashboard-team-member-card">
                      <div className="dashboard-svg-container">
                        <OuterEllipseSvg />
                        <InnerEllipseSvg />
                        {getGaugeComponent(signalScore)}
                      </div>
                      <div className="dashboard-member-score">
                        <span className="dashboard-score-container">
                          <span className="dashboard-signal-score">{signalScore}</span>
                          <span className="dashboard-percentage-symbol">%</span>
                        </span>
                      </div>
                      <div className="dashboard-member-name">
                        {userFirstName} {userLastName}
                      </div>

                      <div className="dashboard-more-details" onClick={() => handleMySignalMoreDetailsClick()}>
                        MORE DETAILS
                      </div>
                    </div>
                  );
                } else if (index <= filteredTeam.length) {
                  const teamIndex = (index - 1 + visibleStartIndexTeam) % filteredTeam.length;
                  const member = filteredTeam[teamIndex];

                  return (
                    <div key={member.UserId} className="dashboard-team-member-card">
                      <div className="dashboard-svg-container">
                        <OuterEllipseSvg />
                        <InnerEllipseSvg />
                        {getGaugeComponent(member.SignalScore)}
                      </div>
                      <div className="dashboard-member-score">
                        <span className="dashboard-score-container">
                          <span className="dashboard-signal-score">{member.SignalScore}</span>
                          <span className="dashboard-percentage-symbol">%</span>
                        </span>
                      </div>
                      <div className="dashboard-member-name">
                        {member.UserFirstName} {member.UserLastName}
                      </div>

                      <div className="dashboard-more-details" onClick={() => handleTeamMoreDetailsClick(member.UserId)}>
                        MORE DETAILS
                      </div>
                    </div>
                  );
                } else {
                  // Return "add team member" card for remaining slots
                  return (
                    <div key={`add-${index}`} className="dashboard-add-team-member-card">
                      <div className="dashboard-add-team-member-svg-container" style={{ position: "relative" }}>
                        <AddTeamMemberCircleSvg />
                        <AddTeamMemberIconSvg />
                      </div>

                      <div className="dashboard-add-team-member-text">Add a team member</div>
                      <button className="dashboard-invite-button" onClick={handleAddMembersButtonClick}>
                        <InviteButtonIconSvg />
                        Invite
                      </button>
                    </div>
                  );
                }
              });
            })()}
        </div>

        <button className="dashboard-team-carousel-button right" onClick={handleNextTeam}>
          <CarouselButtonRightSVG className="carousel-arrow" />
        </button>
      </div>

      <div className="dashboard-services-label-container">
        <h2 className="dashboard-services-label">My Services</h2>
        <button className="dashboard-add-services-button" onClick={handleAddServicesButtonClick}>
          <AddServicesIconSvg />
          Add services
        </button>
      </div>
      <div className="dashboard-services-carousel-container">
        <button className="dashboard-service-carousel-button left" onClick={handlePreviousService}>
          <CarouselButtonLeftSVG className="carousel-arrow" />
        </button>
        <div className="dashboard-services-carousel">
          {Array.from({ length: 5 }).map((_, index) => {
            const serviceIndex = (visibleStartIndexServices + index) % selectedServices.length;
            const service = getServiceAtIndex(serviceIndex);

            // Check if it's a valid service or if we need to add a placeholder
            if (service && index < selectedServices.length) {
              return (
                <div className="dashboard-service-card" key={index}>
                  <div className="dashboard-service-container">
                    <img
                      className="dashboard-service-icon"
                      src={`https://www.signaltuner.com/images/serviceicons/${service.SsServiceName}.png`}
                      alt={`${service.SsServiceName} Icon`}
                    />
                    <div className="dashboard-service-name">{service.SsServiceName}</div>
                    <div className="dashboard-status-details-container">
                      <div className="dashboard-status-content">
                        {renderStatusIcon(service.SsCurrent)}
                        <div className="dashboard-service-status" style={getStatusStyle(service.SsCurrent)}>
                          {service.SsCurrent}
                        </div>
                        <button className="dashboard-details-button" onClick={() => handleServiceDetailsClick(service.SsServiceName, service.SsDescription)}>
                          <ServiceDetailsButtonIconSvg />
                          <ServiceDetailsButtonArrowSvg />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              // Return "add service" card for remaining slots
              return (
                <div key={index} className="dashboard-add-service-card">
                  <div className="dashboard-add-service-svg-container" style={{ position: "relative" }}>
                    <AddServiceCircleSvg />
                    <AddServiceIconSvg />
                  </div>
                  <div className="dashboard-add-team-member-text">Add a service</div>
                  <button className="dashboard-invite-button" onClick={handleAddServicesButtonClick}>
                    <InviteButtonIconSvg />
                    Add
                  </button>
                </div>
              );
            }
          })}
        </div>
        <button className="dashboard-service-carousel-button right" onClick={handleNextService}>
          <CarouselButtonRightSVG className="carousel-arrow" />
        </button>
      </div>

      <div className="dashboard-invite-div">
        <div className="dashboard-invite-content">
          <div className="dashboard-invite-icons-div">
            <InviteIconAiplaneSvg className="dashboard-invite-airplane-icon-svg" />
            <InviteIconSvg className="dashboard-invite-icon-svg" />
          </div>
          <div className="dashboard-invite-text-input-container">
            <p className="dashboard-invite-text">Know someone that needs SignalTuner?</p>
            <div className="input-button-container">
              <input type="text" className="dashboard-share-input" placeholder="Enter an email address" value={shareEmail} onChange={handleShareInputChange} />

              <button className="dashboard-share-button" onClick={handleShareButtonClick}>Share</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
