// Signal.js
import React, { useState, useEffect } from "react";
import Popup from "./Popup";
import "./Signal.css";
import {
  Gauge100,
  Gauge095,
  Gauge090,
  Gauge085,
  Gauge080,
  Gauge075,
  Gauge070,
  Gauge065,
  Gauge060,
  Gauge055,
  Gauge050,
  Gauge045,
  Gauge040,
  Gauge035,
  Gauge030,
  Gauge025,
  Gauge020,
  Gauge015,
  Gauge010,
  Gauge005,
} from "./images/Gauges";
import {
  OuterEllipseSvg,
  InnerEllipseSvg,
  RecommendationIconSvg,
  RecommendationBulletIconSvg,
  RecommendationDetailsArrowIconSvg,
  WorkspaceDivIconSvg,
  WorkspaceCurrentNetworkIconSvg,
  WorkspaceNetworkFrequencyIconSvg,
  WorkspaceWifiStrengthIconSvg,
  WorkspaceVPNIconSvg,
  NetworkDivIconSvg,
  NetworkDownloadIconSvg,
  NetworkUploadIconSvg,
  NetworkLatencyIconSvg,
  NetworkPacketLossIconSvg,
  DeviceDivIconSvg,
  DeviceCPUIconSvg,
  DeviceMemoryIconSvg,
  DeviceProcessorIconSvg,
  DeviceCoresIconSvg,
} from "./images/SignalSVG";

const Signal = ({ signal, activationCode }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isRecommendationModalOpen, setIsRecommendationModalOpen] = useState(false);
  const [recommendationModalContent, setRecommendationModalContent] = useState("");

  useEffect(() => {
    if (signal && signal.SignalOverallStatus === "Unknown") {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [signal]);

  useEffect(() => {
    let timer;
    if (signal && signal.SignalOverallStatus === "Unknown") {
      timer = setTimeout(() => {
        if (signal.SignalOverallStatus === "Unknown") {
          setShowPopup(true);
        }
      }, 30000);
    }

    return () => clearTimeout(timer); // Cleanup timer on unmount or if effect reruns
  }, [signal]);

  // Mapping signal scores to gauge components
  const gaugeComponents = {
    100: Gauge100,
    95: Gauge095,
    90: Gauge090,
    85: Gauge085,
    80: Gauge080,
    75: Gauge075,
    70: Gauge070,
    65: Gauge065,
    60: Gauge060,
    55: Gauge055,
    50: Gauge050,
    45: Gauge045,
    // 40: Gauge040,
    // 35: Gauge035,
    // 30: Gauge030,
    // 25: Gauge025,
    // 20: Gauge020,
    // 15: Gauge015,
    // 10: Gauge010,
    // 5: Gauge005,
  };

  const roundUpToNearestFive = (num) => {
    return Math.ceil(num / 5) * 5;
  };

  // Function to get the appropriate gauge component based on signal score
  const getGaugeComponent = (score) => {
    const roundedScore = roundUpToNearestFive(score);
    const GaugeComponent = gaugeComponents[roundedScore];

    if (!GaugeComponent) {
      return null; // If there's no component for the score, return nothing
    }

    // Render the GaugeComponent with className
    return <GaugeComponent className="gauge-svg" />;
  };

  const openRecommendationModal = (content) => {
    setRecommendationModalContent(content);
    setIsRecommendationModalOpen(true);
  };

  const RecommendationModal = ({ isOpen, content, onClose }) => {
    if (!isOpen) return null;

    return (
      <div className="signal-recommendation-modal-overlay">
        <div className="signal-recommendation-modal">
          <p style={{ whiteSpace: 'pre-line' }}>{content}</p>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

  if (!signal) {
    console.log("Could not retrieve signal data.");
    return <div>Loading signal data...</div>;
  }

  const getColorByStatus = (status) => {
    switch (status) {
      case "Unknown":
        return "#6b96c1";
      case "Critical":
        return "#EC2F3E";
      case "Poor":
        return "#FC6F20";
      case "Fair":
        return "#F9AE00";
      case "Excellent":
        return "#3BB537";
      default:
        return "inherit"; // default color
    }
  };

  return (
    <div>
      <RecommendationModal isOpen={isRecommendationModalOpen} content={recommendationModalContent} onClose={() => setIsRecommendationModalOpen(false)} />
      <div className="signal">
        <div className="signal-score-div">
          <p className="signal-score-label">Signal Score</p>
          <div className="signal-score-meter">
            <OuterEllipseSvg />

            <InnerEllipseSvg />

            {getGaugeComponent(signal.SignalScore)}
          </div>
          <div className="signal-score-value-container">
            <span className="score-value">{signal.SignalScore}</span>
            <span className="score-percentage">%</span>
          </div>
          <div className="signal-recommendations-container">
            <div className="signal-recommendations-label-container">
              <RecommendationIconSvg className="signal-reccommendation-icon-svg" />
              <p className="signal-recommendations-label">Recommendations</p>
            </div>
            {signal.SignalRecommendation1 && (
              <div className="signal-recommendation-1-container" onClick={() => openRecommendationModal(signal.SignalRecommendation1)}>
                <RecommendationBulletIconSvg className="signal-recommendation-1-bullet-svg" />
                <p className="signal-recommendation-1-value" >{signal.SignalRecommendation1}</p>
                <RecommendationDetailsArrowIconSvg className="signal-recommendation-1-details-arrow-svg" />
              </div>
            )}
            {signal.SignalRecommendation2 && (
              <div className="signal-recommendation-2-container" onClick={() => openRecommendationModal(signal.SignalRecommendation2)}>
                <RecommendationBulletIconSvg className="signal-recommendation-2-bullet-svg" />
                <p className="signal-recommendation-2-value">{signal.SignalRecommendation2}</p>
                <RecommendationDetailsArrowIconSvg className="signal-recommendation-2-details-arrow-svg" />
              </div>
            )}
            {signal.SignalRecommendation3 && (
              <div className="signal-recommendation-3-container" onClick={() => openRecommendationModal(signal.SignalRecommendation3)}>
                <RecommendationBulletIconSvg className="signal-recommendation-3-bullet-svg" />
                <p className="signal-recommendation-3-value">{signal.SignalRecommendation3}</p>
                <RecommendationDetailsArrowIconSvg className="signal-recommendation-3-details-arrow-svg" />
              </div>
            )}
          </div>
        </div>
        <div className="device-div">
          <DeviceDivIconSvg />
          <p className="device-text">Device</p>
          <p className="device-status-text" style={{ color: getColorByStatus(signal.SystemStatus) }}>
            {signal.SystemStatus}
          </p>
          <div className="device-left-align-div">
            <div className="device-cpu-div">
              <DeviceCPUIconSvg />
              <p className="device-cpu-label">CPU</p>
            </div>
            <p className="device-cpu-value">{signal.SignalCPU}</p>
            <div className="device-memory-div">
              <DeviceMemoryIconSvg />
              <p className="device-memory-label">Memory</p>
            </div>
            <p className="device-memory-value">{signal.SignalMemory}</p>
            <div className="device-processor-div">
              <DeviceProcessorIconSvg />
              <p className="device-processor-label">Processor</p>
            </div>
            <p className="device-processor-value">{signal.SignalProcessor}</p>
            <div className="device-cores-div">
              <DeviceCoresIconSvg />
              <p className="device-cores-label">Cores</p>
            </div>
            <p className="device-cores-value">{signal.SignalCores}</p>
          </div>
        </div>

        <div className="workspace-div">
          <WorkspaceDivIconSvg />
          <p className="workspace-text">Workspace</p>
          <p className="workspace-status-text" style={{ color: getColorByStatus(signal.SignalWifiStatus) }}>
            {signal.WifiStatus}
          </p>
          <div className="workspace-left-align-div">
            <div className="workspace-current-network-div">
              <WorkspaceCurrentNetworkIconSvg />
              <p className="workspace-current-network-label">Current Network</p>
            </div>
            <p className="workspace-current-network-value">{signal.SignalCurrentNetwork}</p>
            <div className="workspace-network-frequency-div">
              <WorkspaceNetworkFrequencyIconSvg />
              <p className="workspace-frequency-label">Frequency</p>
            </div>
            <p className="workspace-frequency-value">{signal.SignalWifiBand}</p>
            <div className="workspace-wifi-strength-div">
              <WorkspaceWifiStrengthIconSvg />
              <p className="workspace-wifi-strength-label">Wi-Fi Strength</p>
            </div>
            <p className="workspace-wifi-strength-value">{signal.SignalWifiStrength}</p>
            <div className="workspace-vpn-div">
              <WorkspaceVPNIconSvg />
              <p className="workspace-vpn-label">VPN</p>
            </div>
            <p className="workspace-vpn-value">{signal.SignalVpnDetected}</p>
          </div>
        </div>

        <div className="network-div">
          <NetworkDivIconSvg />
          <p className="network-text">Network</p>
          <p className="network-status-text" style={{ color: getColorByStatus(signal.SignalBandwidthStatus) }}>
            {signal.BandwidthStatus}
          </p>
          <div className="network-left-align-div">
            <div className="network-download-div">
              <NetworkDownloadIconSvg />
              <p className="network-download-label">Download</p>
            </div>
            <p className="network-download-value">{signal.SignalDownloadSpeed}</p>
            <div className="network-upload-div">
              <NetworkUploadIconSvg />
              <p className="network-upload-label">Upload</p>
            </div>
            <p className="network-upload-value">{signal.SignalUploadSpeed}</p>
            <div className="network-latency-div">
              <NetworkLatencyIconSvg />
              <p className="network-latency-label">Latency</p>
            </div>
            <p className="network-latency-value">{signal.SignalPing}</p>
            <div className="network-packet-loss-div">
              <NetworkPacketLossIconSvg />
              <p className="network-packet-loss-label">Packet loss</p>
            </div>
            <p className="network-packet-loss-value">{signal.SignalPacketLoss}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signal;
