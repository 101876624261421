import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Team.css";
import {
  AddTeamMemberCircleSvg,
  AddTeamMemberIconSvg,
  InviteButtonIconSvg,
  OuterEllipseSvg,
  InnerEllipseSvg,
  DetailsCloseButtonSvg,
  DetailsIconBackgroundSvg,
  DetailsIconWorkspaceSvg,
  DetailsIconNetworkSvg,
  DetailsIconDeviceSvg,
} from "./images/TeamSVG.js";

import {
  WorkspaceCurrentNetworkIconSvg,
  WorkspaceNetworkFrequencyIconSvg,
  WorkspaceWifiStrengthIconSvg,
  WorkspaceVPNIconSvg,
  NetworkDownloadIconSvg,
  NetworkUploadIconSvg,
  NetworkLatencyIconSvg,
  NetworkPacketLossIconSvg,
  DeviceCPUIconSvg,
  DeviceMemoryIconSvg,
  DeviceProcessorIconSvg,
  DeviceCoresIconSvg,
  RecommendationIconSvg,
} from "./images/SignalSVG";
import {
  CarouselButtonLeftSVG,
  CarouselButtonRightSVG,
  AddServiceCircleSvg,
  AddServiceIconSvg,
  InviteIconSvg,
  InviteIconAiplaneSvg,
  AddServicesIconSvg,
  AddMembersIconSvg,
} from "./images/DashboardSVG";

import { Gauge100, Gauge095, Gauge090, Gauge085, Gauge080, Gauge075, Gauge070, Gauge065, Gauge060, Gauge055, Gauge050, Gauge045 } from "./images/Gauges";

const Team = ({ createTeam, team, leaveTeam, isAdmin, userID, sendInvitation, removeTeamMember, refreshUserAccountInfo, userAccountInfo }) => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const { memberId } = useParams();
  const [teamName, setTeamName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const firstOtherMemberEmail = team?.find((member) => member.UserId !== userID)?.UserEmail || "";
  const [newAdminEmail, setNewAdminEmail] = useState(firstOtherMemberEmail);
  const [inviteEmail, setInviteEmail] = useState("");
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedMemberID, setSelectedMemberID] = useState(null);
  const [selectedMemberFirstName, setSelectedMemberFirstName] = useState("");
  const [selectedMemberLastName, setSelectedMemberLastName] = useState("");
  const [selectedMemberEmail, setSelectedMemberEmail] = useState("");
  const [selectedMemberTeamId, setSelectedMemberTeamId] = useState(null);
  const [selectedMemberTeamName, setSelectedMemberTeamName] = useState("");
  const [selectedMemberIsTeamAdmin, setSelectedMemberIsTeamAdmin] = useState(false);
  const [selectedMemberSignalScore, setSelectedMemberSignalScore] = useState(null);
  const [selectedMemberSignalWifiStrength, setSelectedMemberSignalWifiStrength] = useState(null);
  const [selectedMemberSignalCurrentNetwork, setSelectedMemberSignalCurrentNetwork] = useState("");
  const [selectedMemberSignalWifiStatus, setSelectedMemberSignalWifiStatus] = useState("");
  const [selectedMemberSignalDownloadSpeed, setSelectedMemberSignalDownloadSpeed] = useState(null);
  const [selectedMemberSignalPing, setSelectedMemberSignalPing] = useState(null);
  const [selectedMemberSignalBandwidthStatus, setSelectedMemberSignalBandwidthStatus] = useState("");
  const [selectedMemberSignalMemory, setSelectedMemberSignalMemory] = useState(null);
  const [selectedMemberSignalCpu, setSelectedMemberSignalCpu] = useState(null);
  const [selectedMemberSignalSystemStatus, setSelectedMemberSignalSystemStatus] = useState("");
  const [selectedMemberSignalVpnDetected, setSelectedMemberSignalVpnDetected] = useState(false);
  const [selectedMemberSignalAdapter, setSelectedMemberSignalAdapter] = useState("");
  const [selectedMemberSignalNetworkStatus, setSelectedMemberSignalNetworkStatus] = useState("");
  const [selectedMemberSignalRecommendation1, setSelectedMemberSignalRecommendation1] = useState("");
  const [selectedMemberSignalRecommendation2, setSelectedMemberSignalRecommendation2] = useState("");
  const [selectedMemberSignalRecommendation3, setSelectedMemberSignalRecommendation3] = useState("");
  const [selectedMemberSignalOverallStatus, setSelectedMemberSignalOverallStatus] = useState("");
  const [selectedMemberSignalWifiBand, setSelectedMemberSignalWifiBand] = useState("");
  const [selectedMemberSignalUploadSpeed, setSelectedMemberSignalUploadSpeed] = useState(null);
  const [selectedMemberSignalPacketLoss, setSelectedMemberSignalPacketLoss] = useState(null);
  const [selectedMemberSignalProcessor, setSelectedMemberSignalProcessor] = useState("");
  const [selectedMemberSignalCores, setSelectedMemberSignalCores] = useState(null);
  const [selectedMemberSignalDeviceId, setSelectedMemberSignalDeviceId] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    refreshUserAccountInfo();
  }, [refreshUserAccountInfo]);

  useEffect(() => {
    // Only check for the invite modal state from navigation
    if (location.state?.openInviteModal) {
      setIsInviteModalOpen(true);
      // Clear the navigation state to prevent the modal from opening on refresh or subsequent updates
      navigate("/team", { replace: true });
    }
  }, [location]);

  useEffect(() => {
    if (memberId) {
      const memberFromUrl = team.find((m) => m.UserId.toString() === memberId);
      if (memberFromUrl) {
        openDetailsModal(memberFromUrl); // Ensure this function only sets the selected member without toggling the modal state
        navigate("/team", { replace: true });
      }
    }
  }, [memberId, team]);

  useEffect(() => {
    // If selectedMember exists and team updates, update selectedMember details
    if (selectedMember && team) {
      const updatedMember = team.find((m) => m.UserId === selectedMember.UserId);
      if (updatedMember) {
        setSelectedMember(updatedMember);
        setSelectedMemberFirstName(updatedMember.UserFirstName);
        setSelectedMemberLastName(updatedMember.UserLastName);
        setSelectedMemberEmail(updatedMember.UserEmail);
        setSelectedMemberTeamId(updatedMember.TeamId);
        setSelectedMemberTeamName(updatedMember.TeamName);
        setSelectedMemberIsTeamAdmin(updatedMember.IsTeamAdmin);
        setSelectedMemberSignalScore(updatedMember.SignalScore);
        setSelectedMemberSignalWifiStrength(updatedMember.SignalWifiStrength);
        setSelectedMemberSignalCurrentNetwork(updatedMember.SignalCurrentNetwork);
        setSelectedMemberSignalWifiStatus(updatedMember.SignalWifiStatus);
        setSelectedMemberSignalDownloadSpeed(updatedMember.SignalDownloadSpeed);
        setSelectedMemberSignalPing(updatedMember.SignalPing);
        setSelectedMemberSignalBandwidthStatus(updatedMember.SignalBandwidthStatus);
        setSelectedMemberSignalMemory(updatedMember.SignalMemory);
        setSelectedMemberSignalCpu(updatedMember.SignalCPU);
        setSelectedMemberSignalSystemStatus(updatedMember.SignalSystemStatus);
        setSelectedMemberSignalVpnDetected(updatedMember.SignalVpnDetected);
        setSelectedMemberSignalAdapter(updatedMember.SignalAdapter);
        setSelectedMemberSignalNetworkStatus(updatedMember.SignalNetworkStatus);
        setSelectedMemberSignalRecommendation1(updatedMember.SignalRecommendation1);
        setSelectedMemberSignalRecommendation2(updatedMember.SignalRecommendation2);
        setSelectedMemberSignalRecommendation3(updatedMember.SignalRecommendation3);
        setSelectedMemberSignalOverallStatus(updatedMember.SignalOverallStatus);
        setSelectedMemberSignalWifiBand(updatedMember.SignalWifiBand);
        setSelectedMemberSignalUploadSpeed(updatedMember.SignalUploadSpeed);
        setSelectedMemberSignalPacketLoss(updatedMember.SignalPacketLoss);
        setSelectedMemberSignalProcessor(updatedMember.SignalProcessor);
        setSelectedMemberSignalCores(updatedMember.SignalCores);
        setSelectedMemberSignalDeviceId(updatedMember.SignalDeviceId);
      }
    }
  }, [team, selectedMember]);

  const openDetailsModal = (member) => {
    setSelectedMember(member);
    setSelectedMemberID(member.UserId);
    setSelectedMemberFirstName(member.UserFirstName);
    setSelectedMemberLastName(member.UserLastName);
    setSelectedMemberEmail(member.UserEmail);
    setSelectedMemberTeamId(member.TeamId);
    setSelectedMemberTeamName(member.TeamName);
    setSelectedMemberIsTeamAdmin(member.IsTeamAdmin);
    setSelectedMemberSignalScore(member.SignalScore);
    setSelectedMemberSignalWifiStrength(member.SignalWifiStrength);
    setSelectedMemberSignalCurrentNetwork(member.SignalCurrentNetwork);
    setSelectedMemberSignalWifiStatus(member.SignalWifiStatus);
    setSelectedMemberSignalDownloadSpeed(member.SignalDownloadSpeed);
    setSelectedMemberSignalPing(member.SignalPing);
    setSelectedMemberSignalBandwidthStatus(member.SignalBandwidthStatus);
    setSelectedMemberSignalMemory(member.SignalMemory);
    setSelectedMemberSignalCpu(member.SignalCpu);
    setSelectedMemberSignalSystemStatus(member.SignalSystemStatus);
    setSelectedMemberSignalVpnDetected(member.SignalVpnDetected);
    setSelectedMemberSignalAdapter(member.SignalAdapter);
    setSelectedMemberSignalNetworkStatus(member.SignalNetworkStatus);
    setSelectedMemberSignalRecommendation1(member.SignalRecommendation1);
    setSelectedMemberSignalRecommendation2(member.SignalRecommendation2);
    setSelectedMemberSignalRecommendation3(member.SignalRecommendation3);
    setSelectedMemberSignalOverallStatus(member.SignalOverallStatus);
    setSelectedMemberSignalWifiBand(member.SignalWifiBand);
    setSelectedMemberSignalUploadSpeed(member.SignalUploadSpeed);
    setSelectedMemberSignalPacketLoss(member.SignalPacketLoss);
    setSelectedMemberSignalProcessor(member.SignalProcessor);
    setSelectedMemberSignalCores(member.SignalCores);
    setSelectedMemberSignalDeviceId(member.SignalDeviceId);
    setIsDetailsModalOpen(true);
  };

  const gaugeComponents = {
    100: Gauge100,
    95: Gauge095,
    90: Gauge090,
    85: Gauge085,
    80: Gauge080,
    75: Gauge075,
    70: Gauge070,
    65: Gauge065,
    60: Gauge060,
    55: Gauge055,
    50: Gauge050,
    45: Gauge045,
  };

  const roundUpToNearestFive = (num) => {
    return Math.ceil(num / 5) * 5;
  };

  const getGaugeComponent = (score) => {
    const roundedScore = roundUpToNearestFive(score);
    const GaugeComponent = gaugeComponents[roundedScore];

    if (!GaugeComponent) {
      return null;
    }

    return <GaugeComponent className="gauge-svg" />;
  };

  const getColorByStatus = (status) => {
    switch (status) {
      case "Unknown":
        return "#6b96c1";
      case "Critical":
        return "#EC2F3E";
      case "Poor":
        return "#FC6F20";
      case "Fair":
        return "#F9AE00";
      case "Excellent":
        return "#3BB537";
      default:
        return "inherit"; // default color
    }
  };

  // Calculate the count of recommendations with values
  const recommendationCount = [selectedMemberSignalRecommendation1, selectedMemberSignalRecommendation2, selectedMemberSignalRecommendation3].filter(
    Boolean
  ).length;

  // Determine what to display based on the recommendation count
  let recommendationCountDisplay = "";
  if (recommendationCount === 0) {
    recommendationCountDisplay = "None";
  } else {
    recommendationCountDisplay = recommendationCount.toString();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createTeam(teamName);
    setTeamName("");
  };

  const handleLeaveTeamClick = () => {
    setShowModal(true);
  };

  const handleConfirmLeave = () => {
    leaveTeam(newAdminEmail);
    setShowModal(false);
  };

  const handleSendInvite = async () => {
    if (!inviteEmail) {
      alert("Please enter an email address.");
      return;
    }

    const teamId = team[0].TeamId;
    const invitingUserId = userID;

    const invitationRequest = {
      InvitingUserId: invitingUserId,
      InvitedEmail: inviteEmail,
      TeamId: teamId,
      Accepted: false,
      Declined: false,
    };

    try {
      if (userAccountInfo.UserMonitorsTotal <= userAccountInfo.UserMonitorsUsed) {
        if (userAccountInfo.IsUserCustomer && userAccountInfo.UserStripePlan && userAccountInfo.UserStripePlan !== "cancelled") {
          // If the user is a customer, open the Stripe billing page in a new tab
          window.open("https://billing.stripe.com/p/login/test_14keXN5wReqL2D6cMM", "_blank");
        } else {
          // If the user is not a customer, navigate to the "Plans" page within the same tab
          navigate("/plans"); // Replace '/plans' with the actual path to your Plans page
        }
      } else {
        await sendInvitation(invitationRequest);
        alert("Invitation sent successfully.");
        setInviteEmail("");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      alert("Failed to send invitation.");
    }
  };

  const toggleInviteModal = () => {
    setIsInviteModalOpen(!isInviteModalOpen);
  };

  const toggleDetailsModal = () => {
    setIsDetailsModalOpen(!isDetailsModalOpen);
  };

  const handleConfirmRemove = async () => {
    await removeTeamMember(selectedMemberID); // Use the ID of the member to remove
    setShowRemoveConfirmationModal(false);
    setIsDetailsModalOpen(false);
  };

  return (
    <div>
      <div className="team-container">
        {team && team.length > 0 ? <h2>{team[0].TeamName}</h2> : <h2>Loading team data...</h2>}
        {team && team.length > 0 ? <h3>Team Members: {team.length} </h3> : <h3>Loading team data...</h3>}
        {userAccountInfo ? <h3>Available monitors: {userAccountInfo.UserMonitorsTotal - userAccountInfo.UserMonitorsUsed}</h3> : <h3>Loading team data...</h3>}

        <div className="team-members-grid">
          {team && team.length > 0 ? (
            team
              .sort((a, b) => b.IsTeamAdmin - a.IsTeamAdmin)
              .map((member) => (
                <div key={member.UserId} className="team-member-card">
                  <div className="svg-container">
                    <OuterEllipseSvg />
                    <InnerEllipseSvg />
                    {getGaugeComponent(member.SignalScore)}
                  </div>
                  <div className="member-score">
                    <span className="team-score-container">
                      <span className="signal-score">{member.SignalScore}</span>
                      <span className="percentage-symbol">%</span>
                    </span>
                  </div>
                  <div className="member-name">
                    {member.UserFirstName} {member.UserLastName}
                  </div>

                  <div className="more-details" onClick={() => openDetailsModal(member)}>
                    MORE DETAILS
                  </div>
                </div>
              ))
          ) : (
            <p>No team members to display</p>
          )}

          {isAdmin && (
            <div className="add-team-member-card">
              <div className="add-team-member-svg-container" style={{ position: "relative" }}>
                <AddTeamMemberCircleSvg />
                <AddTeamMemberIconSvg />
              </div>

              <div className="add-team-member-text">Add a team member</div>
              <button className="invite-button" onClick={toggleInviteModal}>
                <InviteButtonIconSvg />
                Invite
              </button>
            </div>
          )}
        </div>
      </div>

      {isDetailsModalOpen && (
        <div className="details-modal">
          <div className="details-modal-content">
            <div onClick={toggleDetailsModal} style={{ cursor: "pointer" }}>
              <DetailsCloseButtonSvg className="details-close-button-svg" />
            </div>
            <p className="details-name">
              {selectedMemberFirstName} {selectedMemberLastName}
            </p>
            <p className="details-email">{selectedMemberEmail}</p>
            <div className="details-overall--status-container">
              <p className="details-overall-status-label">Overall Status:</p>
              <p className="details-overall-status-text" style={{ color: getColorByStatus(selectedMemberSignalOverallStatus) }}>
                {selectedMemberSignalOverallStatus}
              </p>
            </div>

            <div className="details-columns">
              <div className="details-column">
                <div className="device-svg-container">
                  <DetailsIconBackgroundSvg />
                  <DetailsIconDeviceSvg />
                </div>
                <div className="device-text-container">
                  <p className="device-text">Device</p>
                  <p className="device-status-text" style={{ color: getColorByStatus(selectedMemberSignalSystemStatus) }}>
                    {selectedMemberSignalSystemStatus}
                  </p>
                </div>
                <div className="details-values">
                  <div className="device-cpu-div">
                    <DeviceCPUIconSvg />
                    <p className="device-cpu-label">CPU</p>
                  </div>
                  <p className="device-cpu-value">{selectedMemberSignalCpu}</p>
                  <div className="device-memory-div">
                    <DeviceMemoryIconSvg />
                    <p className="device-memory-label">Memory</p>
                  </div>
                  <p className="device-memory-value">{selectedMemberSignalMemory}</p>
                  <div className="device-processor-div">
                    <DeviceProcessorIconSvg />
                    <p className="device-processor-label">Processor</p>
                  </div>
                  <p className="device-processor-value">{selectedMemberSignalProcessor}</p>
                  <div className="device-cores-div">
                    <DeviceCoresIconSvg />
                    <p className="device-cores-label">Cores</p>
                  </div>
                  <p className="device-cores-value">{selectedMemberSignalCores}</p>
                </div>
              </div>

              <div className="details-column">
                <div className="workspace-svg-container">
                  <DetailsIconBackgroundSvg />
                  <DetailsIconWorkspaceSvg />
                </div>
                <div className="workspace-text-container">
                  <p className="workspace-text">Workspace</p>
                  <p className="workspace-status-text" style={{ color: getColorByStatus(selectedMemberSignalWifiStatus) }}>
                    {selectedMemberSignalWifiStatus}
                  </p>
                </div>
                <div className="details-values">
                  <div className="workspace-network-frequency-div">
                    <WorkspaceNetworkFrequencyIconSvg />
                    <p className="workspace-frequency-label">Frequency</p>
                  </div>
                  <p className="workspace-frequency-value">{selectedMemberSignalWifiBand}</p>
                  <div className="workspace-wifi-strength-div">
                    <WorkspaceWifiStrengthIconSvg />
                    <p className="workspace-wifi-strength-label">Wi-Fi Strength</p>
                  </div>
                  <p className="workspace-wifi-strength-value">{selectedMemberSignalWifiStrength}</p>
                  <div className="workspace-vpn-div">
                    <WorkspaceVPNIconSvg />
                    <p className="workspace-vpn-label">VPN</p>
                  </div>
                  <p className="workspace-vpn-value">{selectedMemberSignalVpnDetected ? "Connected" : "Not connected"}</p>
                </div>
              </div>
              <div className="details-column">
                <div className="network-svg-container">
                  <DetailsIconBackgroundSvg />
                  <DetailsIconNetworkSvg />
                </div>
                <div className="network-text-container">
                  <p className="network-text">Network</p>
                  <p className="network-status-text" style={{ color: getColorByStatus(selectedMemberSignalBandwidthStatus) }}>
                    {selectedMemberSignalBandwidthStatus}
                  </p>
                </div>
                <div className="details-values">
                  <div className="network-download-div">
                    <NetworkDownloadIconSvg />
                    <p className="network-download-label">Download</p>
                  </div>
                  <p className="network-download-value">{selectedMemberSignalDownloadSpeed}</p>
                  <div className="network-upload-div">
                    <NetworkUploadIconSvg />
                    <p className="network-upload-label">Upload</p>
                  </div>
                  <p className="network-upload-value">{selectedMemberSignalUploadSpeed}</p>
                  <div className="network-latency-div">
                    <NetworkLatencyIconSvg />
                    <p className="network-latency-label">Latency</p>
                  </div>
                  <p className="network-latency-value">{selectedMemberSignalPing}</p>
                  <div className="network-packet-loss-div">
                    <NetworkPacketLossIconSvg />
                    <p className="network-packet-loss-label">Packet loss</p>
                  </div>
                  <p className="network-packet-loss-value">{selectedMemberSignalPacketLoss}</p>
                </div>
              </div>
            </div>
            <div className="details-recommendations-overview">
              <RecommendationIconSvg className="team-reccommendation-icon-svg" />
              <p className="team-details-recommendations-label">Recommendations:</p>
              <span className="team-details-recommendations-count-display">{recommendationCountDisplay}</span>
            </div>
            {isAdmin && !selectedMemberIsTeamAdmin && (
              <button className="remove-from-team-button" onClick={() => setShowRemoveConfirmationModal(true)}>
                Remove from team
              </button>
            )}
          </div>
        </div>
      )}

      {showRemoveConfirmationModal && (
        <div className="remove-confirmation-modal">
          <div className="remove-confirmation-modal-content">
            <p className="remove-confirmation-modal-text">Are you sure you want to remove {selectedMemberEmail} from your team?</p>
            <button className="remove-confirmation-modal-cancel-button" onClick={() => setShowRemoveConfirmationModal(false)}>
              Cancel
            </button>
            <button className="remove-confirmation-modal-confirm-button" onClick={handleConfirmRemove}>
              Confirm
            </button>
          </div>
        </div>
      )}

      {isAdmin && isInviteModalOpen && (
        <div className="team-invite-modal">
          <div className="team-invite-modal-content">
            <div onClick={toggleInviteModal} style={{ cursor: "pointer" }}>
              <DetailsCloseButtonSvg className="team-invite-modal-close-button" />
            </div>
            <div className="team-invite-input-container">
              <div className="team-invite-icons-div">
                <InviteIconAiplaneSvg className="team-invite-airplane-icon-svg" />
                <InviteIconSvg className="team-invite-icon-svg" />
              </div>
              <div className="team-invite-input-form">
                <p className="team-invite-modal-headline">Invite a new member to your team</p>
                <div className="team-invite-input-form-row">
                  <input
                    className="team-invite-modal-email-input"
                    type="email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                    placeholder="Enter email to invite"
                  />
                  <button
                    className="team-invite-modal-invite-button"
                    onClick={() => {
                      handleSendInvite();
                      setIsInviteModalOpen(false);
                    }}
                  >
                    Send Invitation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isAdmin && <button onClick={handleLeaveTeamClick}>Leave Team</button>}

      {showModal && (
        <div className="modal">
          <p>Are you sure you want to leave the team?</p>
          {isAdmin && (
            <select value={newAdminEmail} onChange={(e) => setNewAdminEmail(e.target.value)}>
              {team
                .filter((member) => member.UserId !== userID)
                .map((member) => (
                  <option key={member.UserId} value={member.UserEmail}>
                    {member.UserName}
                  </option>
                ))}
            </select>
          )}
          <button onClick={handleConfirmLeave}>Yes</button>
          <button onClick={() => setShowModal(false)}>No</button>
        </div>
      )}
    </div>
  );
};

export default Team;
