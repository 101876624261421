import React from "react";

export const LicenseTypeIconSvg = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9 10C11.2091 10 13 8.20914 13 6C13 3.79086 11.2091 2 9 2C6.79086 2 5 3.79086 5 6C5 8.20914 6.79086 10 9 10Z" stroke="#586675" strokeWidth="2" />
    <path
      d="M15 9C15.7956 9 16.5587 8.68393 17.1213 8.12132C17.6839 7.55871 18 6.79565 18 6C18 5.20435 17.6839 4.44129 17.1213 3.87868C16.5587 3.31607 15.7956 3 15 3"
      stroke="#586675"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9 21C12.866 21 16 19.2091 16 17C16 14.7909 12.866 13 9 13C5.13401 13 2 14.7909 2 17C2 19.2091 5.13401 21 9 21Z"
      stroke="#586675"
      strokeWidth="2"
    />
    <path d="M18 14C19.754 14.385 21 15.359 21 16.5C21 17.53 19.986 18.423 18.5 18.87" stroke="#586675" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export const UpdatePaymentIconSvg = ({ className }) => (
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M4 18.0095V12.0095H16.05C17.05 11.3795 18.23 11.0095 19.5 11.0095C20.39 11.0095 21.23 11.1895 22 11.5095V6.00952C22 5.47909 21.7893 4.97038 21.4142 4.59531C21.0391 4.22024 20.5304 4.00952 20 4.00952H4C2.89 4.00952 2 4.89952 2 6.00952V18.0095C2 18.54 2.21071 19.0487 2.58579 19.4237C2.96086 19.7988 3.46957 20.0095 4 20.0095H13.5C13.24 19.3895 13.08 18.7095 13.03 18.0095H4ZM4 6.00952H20V8.00952H4V6.00952ZM23 17.5095C23 18.3295 22.75 19.0895 22.33 19.7195L21.24 18.6295C21.41 18.2895 21.5 17.9095 21.5 17.5095C21.5 16.8465 21.2366 16.2106 20.7678 15.7418C20.2989 15.2729 19.663 15.0095 19 15.0095V16.5095L16.75 14.2595L19 12.0095V13.5095C21.21 13.5095 23 15.2995 23 17.5095ZM19 18.5095L21.25 20.7595L19 23.0095V21.5095C16.79 21.5095 15 19.7195 15 17.5095C15 16.6895 15.25 15.9295 15.67 15.2995L16.76 16.3895C16.59 16.7295 16.5 17.1095 16.5 17.5095C16.5 18.1726 16.7634 18.8084 17.2322 19.2773C17.7011 19.7461 18.337 20.0095 19 20.0095V18.5095Z" fill="#586675"/>
</svg>
  );
