import React, { useState, useEffect } from "react";
import "./Support.css";

const Support = ({ sendSupportEmail }) => {
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const storedEmail = localStorage.getItem("loggedInEmail");

    if (storedEmail) {
      setUserEmail(storedEmail);
    }
  }, []);

  const maxLength = 1000;

  const [text, setText] = useState("");
  const [charCount, setCharCount] = useState(0);

  const handleTextareaChange = (event) => {
    const inputText = event.target.value;
    const currentLength = inputText.length;

    setCharCount(currentLength);

    if (currentLength > maxLength) {
      setText(inputText.substring(0, maxLength));
      setCharCount(maxLength);
    } else {
      setText(inputText);
    }
  };

  const handleSendSupportEmail = async () => {

    const emailInput = document.querySelector(".support-input-email");
    const subjectInput = document.querySelector(".support-input-subject");
    const bodyInput = document.querySelector(".support-input-message");

    const sender = emailInput.value || emailInput.placeholder;
    const subject = subjectInput.value || subjectInput.placeholder;
    const body = bodyInput.value || bodyInput.placeholder;

    // Validate the email is not blank and contains an '@' symbol
    if (!sender || !sender.includes("@")) {
      alert("Please enter a valid email address.");
      return; 
    }

    // Validate that the subject and message are not blank
    if (!subject || !body) {
      alert("Please ensure all fields are filled out.");
      return; 
    }

    const supportEmailRequest = {
      sender,
      subject,
      body,
    };

    try {
      const response = await sendSupportEmail(supportEmailRequest);

      document.querySelector(".support-input-email").value = "";
      document.querySelector(".support-input-subject").value = "";
      setText("");
      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error sending support email:", error.message);
    }
  };

  return (
    <div>
      <div className="support-container">
        <p className="support-label">Support</p>

        <div className="support-input-email-container">
          <input type="text" className="support-input-email" placeholder={userEmail} maxLength={50} />
          <label className="support-input-email-label">From:</label>
        </div>

        <div className="support-input-subject-container">
          <input type="text" className="support-input-subject" placeholder="Support request" maxLength={80} />
          <label className="support-input-subject-label">Subject:</label>
        </div>

        <textarea
          className="support-input-message"
          placeholder="Hello, I have a question about SignalTuner..."
          value={text}
          onChange={handleTextareaChange}
        ></textarea>
        <span className="support-input-message-character-count">
          {charCount} / {maxLength}
        </span>
        <button className="support-send-support-email-button" onClick={handleSendSupportEmail}>
          Send
        </button>
      </div>
    </div>
  );
};

export default Support;
