// import logo from './logo.svg';
import axios from "axios";
import "./App.css";
import React from "react";
import { Component } from "react";
import { Link, Route, Router, Routes, useNavigate, Navigate } from "react-router-dom";
import { predefinedMembers, mockSignalDataSets } from "./MockData";
import config from "./config";
import Home from "./Home";
import Dashboard from "./Dashboard";
import Signal from "./Signal";
import Team from "./Team";
import Services from "./Services";
import Settings from "./Settings";
import Support from "./Support";
import Plans from "./Plans";
import Downloads from "./Downloads";
import ResetPassword from "./ResetPassword";
import Invite from "./Invite";
import Navbar from "./Navbar";
import Demo from "./Demo";
import Popup from "./Popup";
import ProtectedRoute from "./ProtectedRoute";

const API_URL = config.API_URL;

function withNavigation(WrappedComponent) {
  return function (props) {
    const navigate = useNavigate();
    return <WrappedComponent {...props} navigate={navigate} />;
  };
}

const createTeam = async (teamName) => {
  const userEmail = localStorage.getItem("loggedInEmail");

  try {
    const response = await axios.post(`${API_URL}api/User/AddTeam`, {
      UserEmail: userEmail,
      TeamName: teamName,
    });

    if (response.status === 200) {
      alert("Team created successfully.");
      window.location.reload();
    } else {
      alert("Failed to create a team.");
    }
  } catch (error) {
    console.error("Error creating team:", error);
    if (error.response && error.response.status === 400 && error.response.data === "User already has a team.") {
      alert("User already has a team.");
    } else {
      alert("An error occurred while creating the team.");
    }
  }
};

const sendInvitation = async (invitationRequest) => {
  try {
    const response = await fetch(`${API_URL}api/User/SendInvitation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Include other headers like authorization if needed
      },
      body: JSON.stringify(invitationRequest),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to send invitation.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error sending invitation:", error);
    throw error;
  }
};

const sendSupportEmail = async (supportEmailRequest) => {
  try {
    const response = await fetch(`${API_URL}api/User/SendSupportEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Include other headers like authorization if needed
      },
      body: JSON.stringify(supportEmailRequest),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to send support email.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error sending support email:", error);
    throw error;
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.leaveTeam = this.leaveTeam.bind(this);
    const isDemoModeFromStorage = localStorage.getItem("isDemoMode") === "true";
    this.state = {
      users: [],
      signal: null,
      team: null,
      teamServices: [],
      userAccountInfo: null,
      loggedIn: false,
      loggedInEmail: "",
      loading: true,
      showLoginForm: false,
      showUserCreationForm: false,
      showForgotPasswordForm: false,
      userID: "",
      userTeamName: "",
      isAdmin: false,
      isDemoMode: isDemoModeFromStorage,
    };

    this.refreshSignalInterval = null;
    this.toggleDemoMode = this.toggleDemoMode.bind(this);

    this.mockUserDataIndex = parseInt(localStorage.getItem("userMockDataIndex"), 10) || 0; // For refreshSignal()
    this.mockTeamDataIndex = parseInt(localStorage.getItem("teamMockDataIndex"), 10) || 0; // For refreshTeam()
  }

  componentDidMount() {
    // Check for stored user authentication and set the initial state
    this.refreshUserStateFromStorage();
    this.refreshTeam();
    this.refreshUserAccountInfo();
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("loggedInEmail");
    const activationCode = localStorage.getItem("activationCode");

    if (token) {
      this.setState({ loggedIn: true, loggedInEmail: email, activationCode: activationCode, loading: false });
      this.refreshTeamServiceSelections();
      this.refreshTeam();
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loggedIn !== this.state.loggedIn) {
      if (this.state.loggedIn) {
        this.startRefreshSignalProcess();
        this.refreshTeam();
        this.refreshTeamServiceSelections();
      } else {
        this.stopRefreshSignalProcess();
      }
    }
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to prevent memory leaks
    this.stopRefreshSignalProcess();
  }

  isLoggedIn() {
    const token = localStorage.getItem("token");
    return !!token;
  }

  showLoginClick() {
    this.setState({
      showLoginForm: true,
      showUserCreationForm: false,
      showForgotPasswordForm: false,
    });
  }

  showUserCreationClick() {
    this.setState({
      showLoginForm: false,
      showUserCreationForm: true,
      showForgotPasswordForm: false,
    }, () => window.scrollTo(0, 0)); // Callback to ensure scrolling happens after state update
  }

  showForgotPasswordClick() {
    this.setState({
      showLoginForm: false,
      showUserCreationForm: false,
      showForgotPasswordForm: true,
    });
  }

  hideHomePageFormsClick() {
    this.setState({
      showLoginForm: false,
      showUserCreationForm: false,
      showForgotPasswordForm: false,
    });
  }

  toggleDemoMode = () => {
    this.setState(
      (prevState) => ({
        isDemoMode: !prevState.isDemoMode,
      }),
      () => {
        console.log("Demo mode is now:", this.state.isDemoMode);
        localStorage.setItem("isDemoMode", this.state.isDemoMode);
        localStorage.setItem("teamMockDataIndex", "0");
        localStorage.setItem("userMockDataIndex", "0");
      }
    );
  };

  async refreshUsers() {
    fetch(API_URL + "api/User/GetUsers")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ users: data });
      });
  }

  async updateUserInfo() {}

  startRefreshSignalProcess() {
    if (this.isLoggedIn()) {
      if (this.refreshSignalInterval === null) {
        this.refreshSignal();
        this.refreshTeam();

        // Set up a timer to fetch signal data every 10 seconds if the user is logged in
        this.refreshSignalInterval = setInterval(
          () => {
            this.refreshSignal();
            this.refreshTeam();
          },
          this.isDemoMode ? 60000 : 5000
        );
      }
    }
  }

  stopRefreshSignalProcess() {
    // Clear the interval if it is running
    if (this.refreshSignalInterval !== null) {
      clearInterval(this.refreshSignalInterval);
      this.refreshSignalInterval = null;
    }
  }

  async refreshSignal() {
    if (!this.isLoggedIn()) {
      console.log("User not logged in.");
      return;
    }

    const activationCode = localStorage.getItem("activationCode");
    const token = localStorage.getItem("token");

    if (!activationCode) {
      console.log("Activation code not found in local storage");
      return;
    }

    if (!token) {
      console.log("Token not found in local storage");
      return;
    }

    // Check if in demo mode
    const isDemoMode = localStorage.getItem("isDemoMode") === "true";
    if (isDemoMode) {
      // Use the current index to get the mock data
      const mockData = mockSignalDataSets[this.mockUserDataIndex];

      // Update the state with the mock data
      this.setState({ signal: mockData });

      // Increment the index for the next call
      this.mockUserDataIndex = (this.mockUserDataIndex + 1) % mockSignalDataSets.length;
      localStorage.setItem("userMockDataIndex", this.mockUserDataIndex.toString());

      return;
    }

    const url = `${API_URL}api/User/GetSignal/${activationCode}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the Authorization header with the token
      },
    })
      .then((response) => {
        if (response.status === 401) {
          // Token is expired or invalid
          throw new Error("Unauthorized");
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ signal: data });
      })
      .catch((error) => {
        if (error.message === "Unauthorized") {
          // Handle token expiration or invalid token
          this.handleTokenExpiration();
        } else {
          console.error("Error fetching signal data:", error);
        }
      });
  }

  async refreshTeam() {
    if (!this.isLoggedIn()) {
      console.log("User not logged in.");
      return;
    }

    const isDemoMode = localStorage.getItem("isDemoMode") === "true";

    if (isDemoMode) {
      const userFirstName = localStorage.getItem("userFirstName");
      const userLastName = localStorage.getItem("userLastName");
      const userEmail = localStorage.getItem("loggedInEmail");
      const userID = parseInt(localStorage.getItem("userID"), 10);
      const userTeamID = parseInt(localStorage.getItem("userTeamID"), 10);

      const teamMembers = [
        {
          UserId: userID,
          UserFirstName: userFirstName,
          UserLastName: userLastName,
          UserEmail: userEmail,
          TeamId: userTeamID,
          TeamName: "Demonstration Team",
          IsTeamAdmin: true,
          ...mockSignalDataSets[this.mockTeamDataIndex % mockSignalDataSets.length],
        },
        // Predefined members
        ...predefinedMembers.map((member, index) => ({
          ...member,
          UserId: index + 2,
          TeamId: userTeamID,
          TeamName: "Demonstration Team",
          IsTeamAdmin: false,
          ...mockSignalDataSets[(this.mockTeamDataIndex + index + 1) % mockSignalDataSets.length],
        })),
      ];

      this.setState({ team: teamMembers });

      // Increment the index and save it for persistence
      this.mockTeamDataIndex = (this.mockTeamDataIndex + 1) % mockSignalDataSets.length;
      localStorage.setItem("teamMockDataIndex", this.mockTeamDataIndex.toString());

      return;
    }

    // Retrieve userID from local storage instead of activationCode
    const userID = localStorage.getItem("userID");
    const token = localStorage.getItem("token");

    if (!userID) {
      console.log("User ID not found in local storage");
      return;
    }

    if (!token) {
      console.log("Token not found in local storage");
      return;
    }

    // Update the URL to use userID
    const url = `${API_URL}api/User/GetTeam/${userID}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the Authorization header with the token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ team: data });
      })
      .catch((error) => {
        console.error("Error fetching team data:", error);
      });
  }

  refreshTeamServiceSelections = async () => {
    if (!this.isLoggedIn()) {
      console.log("User not logged in.");
      return;
    }

    const userID = localStorage.getItem("userID");
    const token = localStorage.getItem("token");

    if (!userID) {
      console.log("User ID not found in local storage");
      return;
    }

    if (!token) {
      console.log("Token not found in local storage");
      return;
    }

    const url = `${API_URL}api/User/GetAllServicesWithSelectionStatus/${userID}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the Authorization header with the token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ teamServices: data });
      })
      .catch((error) => {
        console.error("Error fetching team service selections:", error);
      });
  };

  removeServiceFromTeam = async (serviceId) => {
    if (!this.isLoggedIn()) {
      console.log("User not logged in.");
      return;
    }

    const userID = localStorage.getItem("userID");
    const token = localStorage.getItem("token");
    const teamId = localStorage.getItem("userTeamID"); // Retrieve the team ID associated with the user

    if (!userID || !token) {
      console.log("User ID or token not found in local storage");
      return;
    }

    try {
      await fetch(`${API_URL}api/User/DeleteTeamServiceSelection`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          UserId: userID,
          TeamId: teamId,
          ServiceStatusId: serviceId,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          console.log(`Service with ID ${serviceId} removed from team.`);
          this.refreshTeamServiceSelections();
        });
    } catch (error) {
      console.error("Error removing service from team:", error);
    }
  };

  async leaveTeam(newAdminEmail) {
    const userEmail = localStorage.getItem("loggedInEmail");

    try {
      const queryParams = new URLSearchParams({ userEmail, newAdminEmail }).toString();
      const response = await fetch(`${API_URL}api/User/LeaveTeam?${queryParams}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        console.log("Successfully left the team.");
      } else {
        console.log("Failed to leave the team.");
      }
    } catch (error) {
      console.error("Error leaving team:", error);
      alert("An error occurred while leaving the team.");
    }
  }

  async removeTeamMember(memberIdToRemove) {
    const adminUserEmail = localStorage.getItem("loggedInEmail");
    const queryParams = new URLSearchParams({ adminUserEmail, memberIdToRemove }).toString();

    try {
      const response = await fetch(`${API_URL}api/User/RemoveTeamMember?${queryParams}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        console.log("Successfully removed the team member.");
      } else {
        console.log("Failed to remove the team member.");
      }
    } catch (error) {
      console.error("Error removing team member:", error);
      alert("An error occurred while removing the team member.");
    }
  }

  async addUserClick() {
    var email = document.getElementById("email").value;
    var password = document.getElementById("password").value;
    var confirmPassword = document.getElementById("confirmPassword").value;
    var firstName = document.getElementById("firstName").value;
    var lastName = document.getElementById("lastName").value;
    var phone = document.getElementById("phone").value; 
    var smsAlerts = document.getElementById("smsAlertsCheckbox").checked;

    // Check if email valid
    if (!email.includes("@")) {
      alert("Email is not valid!");
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    // Check password conditions
    if (password.length < 8) {
      alert("Password must be at least 8 characters long!");
      return;
    }

    if (!/[A-Z]/.test(password)) {
      alert("Password must contain at least one uppercase letter!");
      return;
    }

    if (!/[a-z]/.test(password)) {
      alert("Password must contain at least one lowercase letter!");
      return;
    }

    if (!/[0-9]/.test(password)) {
      alert("Password must contain at least one number!");
      return;
    }

    if (!/[~`!@#$%^&*()\-_+={[}\]|:;"'<,>.?/]/.test(password)) {
      alert("Password must contain at least one special symbol (e.g., ~`!@#$%^&*()_-+={[}]|:;\"'<,>.?/)!");
      return;
    }

    const data = new FormData();
    data.append("email", email);
    data.append("password", password);
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("phone", phone);
    data.append("smsAlerts", smsAlerts);

    return new Promise((resolve, reject) => {
      fetch(API_URL + "api/User/AddUser", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.status === "error" && result.message === "Email already exists") {
            alert("Email already exists. Please use a different email.");
            resolve("error");
          } else {
            alert("Added new user successfully!");
            window.open("https://signaltuner.com/update/counter.php", "_blank");
            resolve("success");
          }
        })
        .catch((error) => {
          console.error("Error adding user:", error);
          alert("An error occurred while creating the account.");
          resolve("error");
        });
    });
  }

  async loginClick(email = null, password = null) {
    if (!email || !password) {
      // Try to get login details from the login form
      const loginEmailElement = document.getElementById("loginEmail");
      const loginPasswordElement = document.getElementById("loginPassword");

      if (loginEmailElement && loginPasswordElement) {
        email = loginEmailElement.value;
        password = loginPasswordElement.value;
      } else {
        // Fallback to account creation form if login form is not available
        const creationEmailElement = document.getElementById("email");
        const creationPasswordElement = document.getElementById("password");

        if (creationEmailElement && creationPasswordElement) {
          email = creationEmailElement.value;
          password = creationPasswordElement.value;
        } else {
          console.error("Email and password elements not found");
          return; // Exit the function if elements are not found
        }
      }
    }

    const loginResponse = await fetch(API_URL + "api/User/Login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (loginResponse.ok) {
      const data = await loginResponse.json();
      localStorage.setItem("token", data.Token);
      localStorage.setItem("loggedInEmail", email);
      localStorage.setItem("activationCode", data.ActivationCode);
      localStorage.setItem("userID", data.UserID);
      localStorage.setItem("userTeamID", data.UserTeamID);
      localStorage.setItem("isAdmin", data.IsTeamAdmin);
      localStorage.setItem("userFirstName", data.UserFirstName);
      localStorage.setItem("userLastName", data.UserLastName);
      this.setState({ loggedIn: true, loggedInEmail: email, activationCode: data.ActivationCode, isAdmin: data.IsTeamAdmin, userID: data.UserID });

      // Check for pending invitations
      const invitationResponse = await fetch(API_URL + "api/User/GetInvitations/" + email);
      if (invitationResponse.ok) {
        const invitations = await invitationResponse.json();
        if (invitations.length > 0) {
          // User has pending invitations, navigate to the invite page
          this.props.navigate("/invite");
        } else {
          // No pending invitations, navigate to the dashboard
          this.startRefreshSignalProcess();
          this.props.navigate("/dashboard");
        }
      } else {
        console.error("Failed to fetch invitations");
        // Handle error or default to dashboard navigation
        this.startRefreshSignalProcess();
        this.props.navigate("/dashboard");
      }
    } else {
      alert("Login failed!");
    }
  }

  refreshUserAccountInfo = async () => {
    if (!this.isLoggedIn()) {
      console.log("User not logged in.");
      return;
    }

    const userID = localStorage.getItem("userID");
    const token = localStorage.getItem("token");

    if (!userID) {
      console.log("User ID not found in local storage");
      return;
    }

    if (!token) {
      console.log("Token not found in local storage");
      return;
    }

    const url = `${API_URL}api/User/GetAccountInfo/${userID}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the Authorization header with the token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Assuming you have a state property to hold the account info
        this.setState({ userAccountInfo: data }, () => {
          console.log(this.state.userAccountInfo); // Use this.state to access the updated state
        });
      })
      .catch((error) => {
        console.error("Error fetching user account info:", error);
      });
  };

  refreshUserStateFromStorage = () => {
    this.setState({
      loggedIn: localStorage.getItem("token") ? true : false,
      loggedInEmail: localStorage.getItem("loggedInEmail") || "",
      userFirstName: localStorage.getItem("userFirstName") || "",
      userLastName: localStorage.getItem("userLastName") || "",
      activationCode: localStorage.getItem("activationCode") || "",
      isAdmin: localStorage.getItem("isAdmin") === "true",
      userID: localStorage.getItem("userID") || "",
      // ... other states
    });
  };

  handleTokenExpiration() {
    console.log("Token expired. Logging out.");
    this.logoutClick();
  }

  logoutClick() {
    localStorage.removeItem("loggedInEmail");
    localStorage.removeItem("activationCode");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("token");
    localStorage.removeItem("userTeamID");
    localStorage.removeItem("userID");
    localStorage.removeItem("teamMockDataIndex");
    localStorage.removeItem("userMockDataIndex");
    localStorage.removeItem("isDemoMode");

    this.setState({ loggedIn: false, loggedInEmail: "" });

    this.stopRefreshSignalProcess();

    this.props.navigate("/home");
  }

  async requestPasswordReset() {
    var email = document.getElementById("resetEmail").value;

    const response = await fetch(API_URL + "api/User/RequestPasswordReset", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `email=${encodeURIComponent(email)}`,
    });

    if (response.ok) {
      alert("Password reset email sent successfully!");
      this.showLoginClick();
    } else {
      alert("Failed to send password reset email!");
      console.log(response);
    }
  }

  render() {
    const { loggedIn, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="App">
        <div>
          <Navbar
            logoutClick={this.logoutClick}
            loggedIn={this.state.loggedIn}
            loggedInEmail={this.state.loggedInEmail}
            showLoginForm={this.state.showLoginForm}
            showLoginClick={() => this.showLoginClick()}
            showUserCreationForm={this.state.showUserCreationForm}
            showUserCreationClick={() => this.showUserCreationClick()}
            showForgotPasswordForm={this.state.showForgotPasswordForm}
            isDemoMode={this.state.isDemoMode}
          />
        </div>

        <div style={{ paddingTop: "70px" }}>
          <Routes>
            <Route
              path="/demo"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Demo isDemoMode={this.state.isDemoMode} toggleDemoMode={this.toggleDemoMode} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Dashboard
                    signal={this.state.signal}
                    team={this.state.team}
                    teamServices={this.state.teamServices}
                    userID={this.state.userID}
                    isAdmin={this.state.isAdmin}
                    loggedInEmail={this.state.loggedInEmail}
                    activationCode={this.state.activationCode}
                    logoutClick={() => this.logoutClick()}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/signal"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Signal signal={this.state.signal} activationCode={this.state.activationCode} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/team/:memberId?"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Team
                    createTeam={createTeam}
                    sendInvitation={sendInvitation}
                    leaveTeam={this.leaveTeam}
                    removeTeamMember={this.removeTeamMember}
                    team={this.state.team}
                    userID={this.state.userID}
                    isAdmin={this.state.isAdmin}
                    refreshUserAccountInfo={this.refreshUserAccountInfo}
                    userAccountInfo={this.state.userAccountInfo}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/services"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Services
                    teamServices={this.state.teamServices}
                    refreshTeamServiceSelections={this.refreshTeamServiceSelections}
                    removeServiceFromTeam={this.removeServiceFromTeam}
                    refreshUserAccountInfo={this.refreshUserAccountInfo}
                    userAccountInfo={this.state.userAccountInfo}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Settings
                    refreshUserStateFromStorage={this.refreshUserStateFromStorage}
                    refreshUserAccountInfo={this.refreshUserAccountInfo}
                    userAccountInfo={this.state.userAccountInfo}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/plans"
              element={
                <ProtectedRoute isLoggedIn={this.isLoggedIn()}>
                  <Plans />
                </ProtectedRoute>
              }
            />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/support" element={<Support sendSupportEmail={sendSupportEmail} />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/invite" element={<Invite />} />
            <Route
              path="/"
              element={
                <Home
                  showLoginForm={this.state.showLoginForm}
                  showLoginClick={() => this.showLoginClick()}
                  showUserCreationForm={this.state.showUserCreationForm}
                  showUserCreationClick={() => this.showUserCreationClick()}
                  showForgotPasswordForm={this.state.showForgotPasswordForm}
                  showForgotPasswordClick={() => this.showForgotPasswordClick()}
                  loggedIn={this.state.loggedIn}
                  loginClick={() => this.loginClick()}
                  logoutClick={() => this.logoutClick()}
                  addClick={() => this.addUserClick()}
                  requestPasswordReset={() => this.requestPasswordReset()}
                  hideHomePageFormsClick={() => this.hideHomePageFormsClick()}
                />
              }
            />
            <Route
              path="/home"
              element={
                <Home
                  showLoginForm={this.state.showLoginForm}
                  showLoginClick={() => this.showLoginClick()}
                  showUserCreationForm={this.state.showUserCreationForm}
                  showUserCreationClick={() => this.showUserCreationClick()}
                  showForgotPasswordForm={this.state.showForgotPasswordForm}
                  showForgotPasswordClick={() => this.showForgotPasswordClick()}
                  loggedIn={this.state.loggedIn}
                  loginClick={() => this.loginClick()}
                  logoutClick={() => this.logoutClick()}
                  addClick={() => this.addUserClick()}
                  requestPasswordReset={() => this.requestPasswordReset()}
                  hideHomePageFormsClick={() => this.hideHomePageFormsClick()}
                  isDemoMode={this.state.isDemoMode}
                />
              }
            />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default withNavigation(App);
