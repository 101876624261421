import React from "react";
import { BulletListCheckmarkSvg, BulletListEllipse } from "./images/HomeSVG";

export const HomeSection2Text = ({ className }) => (
  <div className={className}>
    <p>Today’s remote workers are expected to maintain a professional digital presence.</p>
    <p>If you’re dropping off video calls or not responding to messages because of connectivity failures, you’re not getting the job done.</p>
    <p>SignalTuner makes sure you’re coming in clear with real-time monitoring of all potential problem areas.</p>
  </div>
);

export const HomeSection2BulletList = ({ className }) => (
  <div className={className}>
    <p>
      <BulletListCheckmarkSvg /> In-home wifi signal strength
    </p>
    <p>
      <BulletListCheckmarkSvg /> Network availability
    </p>
    <p>
      <BulletListCheckmarkSvg /> Internet service provider (ISP) performance
    </p>
    <p>
      <BulletListCheckmarkSvg /> System resource allocation
    </p>
    <p>
      <BulletListCheckmarkSvg /> Virtual private network (VPN) connectivity
    </p>
    <p>
      <BulletListCheckmarkSvg /> Application downtime events
    </p>
  </div>
);

export const HomeSection3Headline = ({ className }) => (
  <div className={className}>
    <p>Your home is your office. Make sure it’s set up right!</p>
  </div>
);

export const HomeSection3Text = ({ className }) => (
  <div className={className}>
    <p>SignalTuner’s real time Wifi Signal monitoring will let you know you’re coming in strong.</p>
    <p>
      Even if you think you’re close enough to your wifi router, doors, walls, and electronic interference can make a big difference in your signal strength.
    </p>
    <p>SignalTuner makes sure you’re coming in clear with real-time monitoring of all potential problem areas.</p>
  </div>
);

export const HomeSection4Headline = ({ className }) => (
  <div className={className}>
    <p>Monitor the tools you use. Don’t let downtime leave you disconnected.</p>
  </div>
);

export const HomeSection4Text = ({ className }) => (
  <div className={className}>
    <p>We depend on remote collaboration tools more and more everyday.</p>
    <p>Zoom, Slack, Microsoft Teams, Google Meet... everyone has downtime eventually.</p>
    <p>Don’t leave yourself unprepared. Get instant alerts when your tools have outages, so you can plan around them.</p>
  </div>
);

export const HomeSection5Headline = ({ className }) => (
  <div className={className}>
    <p>Every potential point of failure, accounted for.</p>
    <p>Take responsibility for your digital presence.</p>
  </div>
);

export const HomeSection5Text = ({ className }) => (
  <div className={className}>
    <p>Connection issues are detected by SignalTuner before they become problems for you.</p>
    <p>Anything that can stand between you and your job is monitored and your alerted to what needs fixing.</p>
    <p>
      <BulletListEllipse />
      Weak wifi signals
    </p>
    <p>
      <BulletListEllipse />
      Local ISP outages
    </p>
    <p>
      <BulletListEllipse />
      Non-essential apps using all your CPU time or RAM
    </p>
    <p>
      <BulletListEllipse />
      VPN performance impacts
    </p>
    <p>
      <BulletListEllipse />
      Application network downtime
    </p>
    <p> Don’t let anything prevent you for connecting with your colleagues</p>
  </div>
);

export const HomeSection6Headline = ({ className }) => (
  <div className={className}>
    <p>You’re free from the office. Make the most of your space.</p>
  </div>
);

export const HomeSection6Text = ({ className }) => (
  <div className={className}>
    <p>Work from anywhere, as long as your signal supports it.</p>
  </div>
);

export const HomeSection6BannerText = ({ className }) => (
    <p className={className}>Start using SignalTuner FREE today!</p>
);
