import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import config from './config';

const Invite = () => {
    const [invitations, setInvitations] = useState([]);
    const navigate = useNavigate(); // Initialize the navigate function

    const API_URL= config.API_URL;

    useEffect(() => {
        fetchInvitations();
    }, []);

    const fetchInvitations = async () => {
        const userEmail = localStorage.getItem('loggedInEmail');
        const response = await fetch(`${API_URL}api/User/GetInvitations/${userEmail}`);
        if (response.ok) {
            const data = await response.json();
            setInvitations(data);
        }
    };

    const handleResponse = async (invitationId, accept) => {
        // Prepare form data
        const formData = new FormData();
        formData.append('invitationId', invitationId);
        formData.append('accept', accept);
    
        // Send response to the server
        const response = await fetch(`${API_URL}api/User/RespondToInvitation`, {
            method: 'POST',
            body: formData
        });
    
        if (response.ok) {
            // Navigate to the dashboard page after handling the invitation
            navigate('/dashboard');
        }
    };

    return (
        <div>
            {invitations.length > 0 ? (
                invitations.map(invitation => (
                    <div key={invitation.InvitationId}>
                        <p>You have been invited to join {invitation.InvitedTeamName} by {invitation.InvitingUserName}</p>
                        <button onClick={() => handleResponse(invitation.InvitationId, true)}>Accept</button>
                        <button onClick={() => handleResponse(invitation.InvitationId, false)}>Decline</button>
                    </div>
                ))
            ) : (
                <p>You have no current team invites.</p>
            )}
        </div>
    );
};

export default Invite;