import React from "react";

export const RemoveServiceButtonSvg = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M22.8748 7.1375C22.7591 7.02162 22.6218 6.92969 22.4706 6.86696C22.3194 6.80423 22.1572 6.77194 21.9935 6.77194C21.8298 6.77194 21.6677 6.80423 21.5165 6.86696C21.3653 6.92969 21.2279 7.02162 21.1123 7.1375L14.9998 13.2375L8.88729 7.125C8.77156 7.00927 8.63418 6.91747 8.48297 6.85484C8.33177 6.79221 8.1697 6.75997 8.00604 6.75997C7.84238 6.75997 7.68032 6.79221 7.52911 6.85484C7.37791 6.91747 7.24052 7.00927 7.12479 7.125C7.00906 7.24073 6.91726 7.37812 6.85463 7.52932C6.792 7.68053 6.75977 7.84259 6.75977 8.00625C6.75977 8.16991 6.792 8.33197 6.85463 8.48318C6.91726 8.63438 7.00906 8.77177 7.12479 8.8875L13.2373 15L7.12479 21.1125C7.00906 21.2282 6.91726 21.3656 6.85463 21.5168C6.792 21.668 6.75977 21.8301 6.75977 21.9937C6.75977 22.1574 6.792 22.3195 6.85463 22.4707C6.91726 22.6219 7.00906 22.7593 7.12479 22.875C7.24052 22.9907 7.37791 23.0825 7.52911 23.1452C7.68032 23.2078 7.84238 23.24 8.00604 23.24C8.1697 23.24 8.33177 23.2078 8.48297 23.1452C8.63418 23.0825 8.77156 22.9907 8.88729 22.875L14.9998 16.7625L21.1123 22.875C21.228 22.9907 21.3654 23.0825 21.5166 23.1452C21.6678 23.2078 21.8299 23.24 21.9935 23.24C22.1572 23.24 22.3193 23.2078 22.4705 23.1452C22.6217 23.0825 22.7591 22.9907 22.8748 22.875C22.9905 22.7593 23.0823 22.6219 23.1449 22.4707C23.2076 22.3195 23.2398 22.1574 23.2398 21.9937C23.2398 21.8301 23.2076 21.668 23.1449 21.5168C23.0823 21.3656 22.9905 21.2282 22.8748 21.1125L16.7623 15L22.8748 8.8875C23.3498 8.4125 23.3498 7.6125 22.8748 7.1375Z"
      fill="#B4B9BD"
    />
  </svg>
);

export const ServiceDetailsButtonIconSvg = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M6.99984 1.1655C10.2222 1.1655 12.8343 3.77766 12.8343 7C12.8343 10.2217 10.2222 12.8333 6.99984 12.8333C3.7775 12.8339 1.1665 10.2217 1.1665 7C1.16592 3.77766 3.7775 1.1655 6.99984 1.1655ZM6.9975 5.978C6.85455 5.97819 6.71664 6.03088 6.60997 6.12604C6.50329 6.22121 6.43528 6.35224 6.41884 6.49425L6.41475 6.56191L6.41709 9.77141L6.42059 9.83908C6.43689 9.98136 6.50496 10.1127 6.61184 10.208C6.71871 10.3033 6.85692 10.356 7.00013 10.356C7.14334 10.356 7.28155 10.3033 7.38842 10.208C7.4953 10.1127 7.56337 9.98136 7.57967 9.83908L7.58317 9.77083L7.58084 6.56133L7.57675 6.49308C7.55989 6.35119 7.49154 6.22042 7.38466 6.12559C7.27778 6.03075 7.13981 5.97844 6.99692 5.97858L6.9975 5.978ZM7.00042 3.79108C6.8068 3.79108 6.62111 3.86799 6.4842 4.0049C6.34729 4.14181 6.27038 4.3275 6.27038 4.52112C6.27038 4.71474 6.34729 4.90043 6.4842 5.03734C6.62111 5.17425 6.8068 5.25116 7.00042 5.25116C7.09625 5.25112 7.19114 5.23221 7.27966 5.1955C7.36818 5.15879 7.44861 5.10501 7.51635 5.03722C7.58408 4.96943 7.6378 4.88896 7.67444 4.80041C7.71108 4.71185 7.72992 4.61695 7.72988 4.52112C7.72984 4.42529 7.71093 4.3304 7.67422 4.24188C7.63751 4.15336 7.58372 4.07293 7.51593 4.0052C7.44814 3.93746 7.36767 3.88374 7.27912 3.8471C7.19057 3.81046 7.09567 3.79163 6.99984 3.79166L7.00042 3.79108Z"
      fill="#A2A2A2"
    />
  </svg>
);

export const ServiceDetailsButtonArrowSvg = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <g clipPath="url(#clip0_78_290)">
      <path
        d="M8.27549 2.87034C8.31439 2.83358 8.36014 2.80485 8.41014 2.78578C8.46014 2.7667 8.51341 2.75766 8.5669 2.75918C8.6204 2.76069 8.67307 2.77273 8.72191 2.79459C8.77075 2.81646 8.81481 2.84774 8.85157 2.88663C8.88832 2.92553 8.91706 2.97128 8.93613 3.02129C8.9552 3.07129 8.96424 3.12455 8.96273 3.17805C8.96121 3.23154 8.94918 3.28421 8.92731 3.33305C8.90544 3.3819 8.87417 3.42595 8.83527 3.46271L5.1686 6.92567C5.09296 6.99719 4.99281 7.03703 4.88872 7.03703C4.78462 7.03703 4.68447 6.99719 4.60883 6.92567L0.941752 3.46271C0.902005 3.4262 0.869908 3.38215 0.847328 3.33313C0.824748 3.2841 0.812133 3.23108 0.810217 3.17715C0.808301 3.12321 0.817121 3.06943 0.836165 3.01893C0.85521 2.96842 0.884099 2.92221 0.921154 2.88297C0.95821 2.84373 1.00269 2.81224 1.05202 2.79033C1.10135 2.76843 1.15454 2.75654 1.2085 2.75537C1.26246 2.75419 1.31611 2.76375 1.36635 2.78349C1.41658 2.80323 1.46239 2.83275 1.50112 2.87034L4.88872 6.0693L8.27549 2.87034Z"
        fill="#586675"
      />
    </g>
    <defs>
      <clipPath id="clip0_78_290">
        <rect
          width="9.77778"
          height="9.77778"
          fill="white"
          transform="matrix(-1.19249e-08 -1 -1 1.19249e-08 9.77783 9.88889)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const MonitorButtonArrowSvg = () => (
    <svg className="monitor-icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      d="M19.4 8.95476C19.0208 8.03436 18.475 7.20024 17.8 6.49C16.3424 4.95622 14.2829 4 12 4C7.58172 4 4 7.58172 4 12H8L10 15.2L13.6 8L16.4 12H20C20 16.4183 16.4183 20 12 20C9.81964 20 7.843 19.1278 6.4 17.7132C5.6719 16.9994 5.07967 16.1475 4.66567 15.2"
      stroke="#6B96C1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const NoIssueStatusIconSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
    <path
      d="M6.72086 0.970002C6.85669 0.835891 7.03746 0.761749 7.225 0.76322C7.41255 0.764692 7.59221 0.841662 7.72607 0.97789C7.85994 1.11412 7.93753 1.29895 7.94249 1.49338C7.94745 1.68781 7.87938 1.87663 7.75264 2.02L3.90514 7.01C3.83898 7.0839 3.75914 7.1432 3.67037 7.18437C3.58161 7.22553 3.48575 7.24771 3.38853 7.24958C3.29131 7.25145 3.19472 7.23296 3.10455 7.19524C3.01438 7.15751 2.93247 7.10131 2.86371 7.03L0.312214 4.384C0.241159 4.31534 0.184168 4.23254 0.14464 4.14054C0.105112 4.04854 0.0838571 3.94923 0.0821438 3.84853C0.0804305 3.74782 0.0982937 3.64779 0.134668 3.5544C0.171041 3.46102 0.225181 3.37618 0.293856 3.30496C0.362531 3.23375 0.444335 3.1776 0.534388 3.13988C0.624441 3.10216 0.720898 3.08363 0.818004 3.08541C0.91511 3.08719 1.01088 3.10923 1.09959 3.15022C1.1883 3.19121 1.26815 3.25032 1.33436 3.324L3.35357 5.417L6.70254 0.992002C6.70857 0.984303 6.71404 0.976958 6.72086 0.970002Z"
      fill="white"
    />
  </svg>
);

export const IncidentStatusIconSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M5.56655 0.0351496C4.81187 0.201556 4.2353 0.799212 4.08765 1.56562C4.01968 1.91484 4.02671 1.95468 4.52358 4.79296C4.77905 6.26249 5.0064 7.51171 5.02749 7.57031C5.19155 8.03437 5.66968 8.31093 6.16655 8.2289C6.55562 8.16562 6.84858 7.92656 6.9728 7.57031C6.9939 7.51171 7.22124 6.26249 7.47671 4.79296C7.82827 2.78906 7.94546 2.07187 7.94546 1.9289C7.94546 1.39921 7.73687 0.907025 7.3478 0.524993C7.09937 0.2789 6.78062 0.110149 6.42437 0.0328064C6.23218 -0.00703812 5.75405 -0.00703812 5.56655 0.0351496Z"
      fill="white"
    />
    <path
      d="M5.77041 9.04922C5.2126 9.13125 4.73682 9.54609 4.56807 10.1016C4.52588 10.2352 4.51416 10.3336 4.51416 10.5117C4.51416 10.95 4.65244 11.2898 4.94307 11.5781C5.24307 11.8758 5.55479 12 6.0001 12C6.44775 12 6.76182 11.8734 7.05713 11.5805C7.34775 11.2875 7.48603 10.95 7.48603 10.5117C7.48838 9.76875 6.94463 9.15 6.204 9.04922C5.99775 9.02109 5.979 9.02109 5.77041 9.04922Z"
      fill="white"
    />
  </svg>
);

export const OutageStatusIconSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M10.6751 3.33083C10.6212 3.27675 10.5571 3.23385 10.4865 3.20457C10.4159 3.1753 10.3403 3.16023 10.2639 3.16023C10.1875 3.16023 10.1118 3.1753 10.0413 3.20457C9.9707 3.23385 9.9066 3.27675 9.85263 3.33083L7.00013 6.17749L4.14763 3.32499C4.09362 3.27099 4.02951 3.22815 3.95895 3.19892C3.88838 3.16969 3.81276 3.15465 3.73638 3.15465C3.66 3.15465 3.58438 3.16969 3.51381 3.19892C3.44325 3.22815 3.37914 3.27099 3.32513 3.32499C3.27112 3.379 3.22828 3.44311 3.19906 3.51368C3.16983 3.58424 3.15479 3.65987 3.15479 3.73624C3.15479 3.81262 3.16983 3.88825 3.19906 3.95881C3.22828 4.02937 3.27112 4.09349 3.32513 4.14749L6.17763 6.99999L3.32513 9.85249C3.27112 9.9065 3.22828 9.97061 3.19906 10.0412C3.16983 10.1117 3.15479 10.1874 3.15479 10.2637C3.15479 10.3401 3.16983 10.4157 3.19906 10.4863C3.22828 10.5569 3.27112 10.621 3.32513 10.675C3.37914 10.729 3.44325 10.7718 3.51381 10.8011C3.58438 10.8303 3.66 10.8453 3.73638 10.8453C3.81276 10.8453 3.88838 10.8303 3.95895 10.8011C4.02951 10.7718 4.09362 10.729 4.14763 10.675L7.00013 7.82249L9.85263 10.675C9.90664 10.729 9.97075 10.7718 10.0413 10.8011C10.1119 10.8303 10.1875 10.8453 10.2639 10.8453C10.3403 10.8453 10.4159 10.8303 10.4864 10.8011C10.557 10.7718 10.6211 10.729 10.6751 10.675C10.7291 10.621 10.772 10.5569 10.8012 10.4863C10.8304 10.4157 10.8455 10.3401 10.8455 10.2637C10.8455 10.1874 10.8304 10.1117 10.8012 10.0412C10.772 9.97061 10.7291 9.9065 10.6751 9.85249L7.82263 6.99999L10.6751 4.14749C10.8968 3.92583 10.8968 3.55249 10.6751 3.33083Z"
      fill="white"
    />
  </svg>
);