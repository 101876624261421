import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Demo.css";

const Demo = ({ isDemoMode, toggleDemoMode }) => {
  const [timer, setTimer] = useState(null);
  const [countdownInterval, setCountdownInterval] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);

  const handleTimerEnd = () => {
    toggleDemoMode();
    setTimeLeft(0); // Reset time left
    localStorage.removeItem("countdownEndTime"); // Clear saved end time
  };

  const clearTimer = () => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    if (countdownInterval) {
      clearInterval(countdownInterval);
      setCountdownInterval(null);
    }
    localStorage.removeItem('countdownEndTime'); // Clear saved end time
    setTimeLeft(0); // Reset time left
  };

  const handleButtonClick = () => {
    toggleDemoMode();
    if (!isDemoMode) {
      startTimer(); // Start the timer only if we're switching to demo mode
      window.location.reload()
    } else {
      clearTimer(); // Clear the timer if we're turning off demo mode
      window.location.reload()
    }
  };

  const startTimer = (duration = 5 * 60 * 1000) => {
    clearTimer(); // Clear any existing timer and interval

    // Start new timer and interval
    const endTime = Date.now() + duration;
    localStorage.setItem('countdownEndTime', endTime.toString());

    const newTimer = setTimeout(() => {
      toggleDemoMode(); // Toggle demo mode off when timer ends
      clearTimer();
      window.location.reload()
    }, duration);

    const newInterval = setInterval(() => {
      const remainingTime = Math.max(0, Math.floor((endTime - Date.now()) / 1000));
      if (remainingTime <= 0) {
        clearInterval(newInterval);
        setCountdownInterval(null);
      }
      setTimeLeft(remainingTime);
    }, 1000);

    setTimer(newTimer);
    setCountdownInterval(newInterval);
  };

  useEffect(() => {
    // Check if there's a saved countdown end time
    const startInitialTimer = () => {
      const savedEndTime = localStorage.getItem('countdownEndTime');
      if (savedEndTime) {
        const remainingTime = Math.max(0, Math.floor((parseInt(savedEndTime) - Date.now()) / 1000));
        if (remainingTime > 0) {
          setTimeLeft(remainingTime);
          startTimer(remainingTime * 1000); // Resume the timer
        }
      }
    };

    startInitialTimer();

    // Cleanup function to clear timer and interval
    return () => {
      clearTimer();
    };
  }, []);

  return (
    <div>
      <button className="demo-mode-button" onClick={handleButtonClick}>
        {isDemoMode ? "Switch to Live Mode" : "Switch to Demo Mode"}
      </button>
      <div className="demo-mode-status">{isDemoMode ? "Demo Mode is ON" : "Demo Mode is OFF"}</div>
      <div>Time remaining: {timeLeft} seconds</div>
      {/* ... other content */}
    </div>
  );
};

Demo.propTypes = {
  isDemoMode: PropTypes.bool.isRequired,
  toggleDemoMode: PropTypes.func.isRequired,
};

export default Demo;
