import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Settings.css";
import { LicenseTypeIconSvg, UpdatePaymentIconSvg } from "./images/SettingsSVG.js";
import config from "./config";

const Settings = ({ refreshUserStateFromStorage, refreshUserAccountInfo, userAccountInfo }) => {
  const [selectedTab, setSelectedTab] = useState("Account");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const userID = localStorage.getItem("userID");
  const userTeamID = parseInt(localStorage.getItem("userTeamID"), 10);
  const [startTime, setStartTime] = useState(0);
  const [stopTime, setStopTime] = useState(0);
  const [timeZone, setTimeZone] = useState("UTC+00:00");
  const [memberAlertThreshold, setMemberAlertThreshold] = useState("Critical");
  const [memberAlertTime, setMemberAlertTime] = useState("15");
  const [serviceAlertsEmailEnabled, setServiceAlertsEmailEnabled] = useState("true");
  const [teamMemberAlertsEmailEnabled, setTeamMemberAlertsEmailEnabled] = useState("true");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const API_URL = config.API_URL;

  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const getButtonClass = (buttonName) => {
    let baseClass = "settings-button"; // common base class for all buttons
    if (selectedTab === buttonName) {
      baseClass += " selected"; // add 'selected' class if the button is the current selection
    }
    return baseClass;
  };

  const handleUpdatePlanClick = () => {
    if (userAccountInfo.IsUserCustomer && userAccountInfo.UserStripePlan && userAccountInfo.UserStripePlan !== "cancelled") {
      // If the user is a customer, open the Stripe billing page in a new tab
      window.open("https://billing.stripe.com/p/login/test_14keXN5wReqL2D6cMM", "_blank");
    } else {
      // If the user is not a customer, navigate to the "Plans" page within the same tab
      navigate("/plans"); // Replace '/plans' with the actual path to your Plans page
    }
  };

  const updateTeamSettings = async () => {
    const formData = new FormData();
    formData.append("teamId", userTeamID);
    formData.append("startTime", startTime);
    formData.append("stopTime", stopTime);
    formData.append("timeZone", timeZone);
    formData.append("memberAlertThreshold", memberAlertThreshold);
    formData.append("memberAlertTime", memberAlertTime);
    formData.append("serviceAlertsEmailEnabled", serviceAlertsEmailEnabled);
    formData.append("teamMemberAlertsEmailEnabled", teamMemberAlertsEmailEnabled);
    console.log("Form Data:", formData);

    try {
      const response = await fetch(API_URL + "api/User/UpdateTeamSettings", {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
      console.log(result); // Handle the response based on your application's needs
    } catch (error) {
      console.error("Error updating team settings:", error);
    }
  };

  const updateUser = async () => {
    const trimmedUserEmail = userEmail.trimEnd();
    const trimmedUserFirstName = userFirstName.trimEnd();
    const trimmedUserLastName = userLastName.trimEnd();

    const formData = new FormData();
    formData.append("userId", userID);
    formData.append("userEmail", trimmedUserEmail);
    formData.append("userFirstName", trimmedUserFirstName);
    formData.append("userLastName", trimmedUserLastName);

    try {
      const response = await fetch(API_URL + "api/User/UpdateUser", {
        method: "PUT",
        body: formData,
      });
      const result = await response.json();
      console.log(result); // Handle the response based on your application's needs

      // Check if the update was successful
      if (result.status === "success") {
        // Set the updated values in local storage
        localStorage.setItem("userFirstName", trimmedUserFirstName);
        localStorage.setItem("userLastName", trimmedUserLastName);
        localStorage.setItem("loggedInEmail", trimmedUserEmail);

        refreshUserStateFromStorage();

        // Clear user inputs
        // setUserFirstName("");
        // setUserLastName("");
        // setUserEmail("");

        alert("User updated successfully!"); // Show success message
      } else {
        alert(result.message); // Show error message from server
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const updatePassword = async () => {
    // Check if passwords match
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    // Check password conditions
    if (newPassword.length < 8) {
      alert("Password must be at least 8 characters long!");
      return;
    }

    if (!/[A-Z]/.test(newPassword)) {
      alert("Password must contain at least one uppercase letter!");
      return;
    }

    if (!/[a-z]/.test(newPassword)) {
      alert("Password must contain at least one lowercase letter!");
      return;
    }

    if (!/[0-9]/.test(newPassword)) {
      alert("Password must contain at least one number!");
      return;
    }

    if (!/[~`!@#$%^&*()\-_+={[}\]|:;"'<,>.?/]/.test(newPassword)) {
      alert("Password must contain at least one special symbol (e.g., ~`!@#$%^&*()_-+={[}]|:;\"'<,>.?/)!");
      return;
    }

    const formData = new FormData();
    formData.append("newPassword", newPassword);

    try {
      const response = await fetch(`${API_URL}api/User/UpdatePassword/${userID}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.ok) {
        const result = await response.text();
        setNewPassword("");
        setConfirmPassword("");
        alert(result); // Show success message
      } else {
        const error = await response.text();
        alert(error); // Show error message from server
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  useEffect(() => {
    // Retrieve the userFirstName value from local storage
    const storedFirstName = localStorage.getItem("userFirstName");
    const storedLastName = localStorage.getItem("userLastName");
    const storedEmail = localStorage.getItem("loggedInEmail");
    if (storedFirstName) {
      setUserFirstName(storedFirstName);
    }
    if (storedLastName) {
      setUserLastName(storedLastName);
    }
    if (storedEmail) {
      setUserEmail(storedEmail);
    }
  }, []);

  useEffect(() => {
    refreshUserAccountInfo();
  }, [refreshUserAccountInfo]);

  useEffect(() => {
    // This will print the account info to the console whenever it updates
    console.log("Updated User Account Info:", userAccountInfo);
  }, [userAccountInfo]); // This effect depends on `userAccountInfo`

  useEffect(() => {
    const loadTeamSettings = async () => {
      try {
        const response = await fetch(API_URL + `api/User/GetTeamSettings?teamId=${userTeamID}`);
        if (response.ok) {
          const data = await response.json();
          setStartTime(data.StartTime);
          setStopTime(data.StopTime);
          setTimeZone(data.TimeZone);
          setMemberAlertThreshold(data.MemberAlertThreshold);
          console.log("MemberAlertTime:", data.MemberAlertTime);
          setMemberAlertTime(data.MemberAlertTime.toString());
          setServiceAlertsEmailEnabled(data.ServiceAlertsEmailEnabled.toString());
          setTeamMemberAlertsEmailEnabled(data.MemberAlertsEmailEnabled.toString());
        } else {
          console.error("Failed to load team settings");
        }
      } catch (error) {
        console.error("Error loading team settings:", error);
      }
    };

    loadTeamSettings();
  }, [userTeamID]);

  const renderContent = () => {
    switch (selectedTab) {
      case "Alerts":
        return (
          <div>
            <div className="settings-working-hours-container">
              <p className="settings-working-hours-label">Working hours</p>

              <div className="settings-working-hours-dropdowns-container">
                <div className="settings-working-hours-start-dropdown-container">
                  <span className="settings-working-hours-start-dropdown-label">Start</span>
                  <select className="settings-working-start-hours-dropdown" value={startTime} onChange={(e) => setStartTime(e.target.value)}>
                    {Array.from({ length: 24 }, (_, index) => {
                      const hour = index === 0 ? 12 : index > 12 ? index - 12 : index;
                      const suffix = index < 12 ? "AM" : "PM";
                      return <option key={index} value={index}>{`${hour}:00 ${suffix}`}</option>;
                    })}
                  </select>
                </div>

                <div className="settings-working-hours-finish-dropdown-container">
                  <span className="settings-working-hours-finish-dropdown-label">Finish</span>
                  <select className="settings-working-finish-hours-dropdown" value={stopTime} onChange={(e) => setStopTime(e.target.value)}>
                    {Array.from({ length: 24 }, (_, index) => {
                      const hour = index === 0 ? 12 : index > 12 ? index - 12 : index;
                      const suffix = index < 12 ? "AM" : "PM";
                      return <option key={index} value={index}>{`${hour}:00 ${suffix}`}</option>;
                    })}
                  </select>
                </div>

                <div className="settings-working-hours-timezone-dropdown-container">
                  <span className="settings-working-hours-timezone-dropdown-label">Time Zone</span>
                  <select className="settings-working-hours-timezone-dropdown" value={timeZone} onChange={(e) => setTimeZone(e.target.value)}>
                    <option value="Pacific/Kwajalein">UTC-12:00 (Eniwetok, Kwajalein)</option>
                    <option value="Pacific/Pago_Pago">UTC-11:00 (Pago Pago, American Samoa)</option>
                    <option value="Pacific/Honolulu">UTC-10:00 (Honolulu, United States)</option>
                    <option value="Pacific/Marquesas">UTC-09:30 (Taiohae, Marquesas Islands)</option>
                    <option value="America/Anchorage">UTC-09:00 (Anchorage, United States)</option>
                    <option value="America/Los_Angeles">UTC-08:00 (Los Angeles, United States)</option>
                    <option value="America/Phoenix">UTC-07:00 (Phoenix, United States)</option>
                    <option value="America/Chicago">UTC-06:00 (Chicago, United States)</option>
                    <option value="America/New_York">UTC-05:00 (New York, United States)</option>
                    <option value="America/Caracas">UTC-04:00 (Caracas, Venezuela)</option>
                    <option value="America/St_Johns">UTC-03:30 (St. John's, Canada)</option>
                    <option value="America/Argentina/Buenos_Aires">UTC-03:00 (Buenos Aires, Argentina)</option>
                    <option value="Atlantic/South_Georgia">UTC-02:00 (Mid-Atlantic)</option>
                    <option value="Atlantic/Cape_Verde">UTC-01:00 (Praia, Cape Verde)</option>
                    <option value="Europe/London">UTC+00:00 (GMT) (London, United Kingdom)</option>
                    <option value="Europe/Paris">UTC+01:00 (Paris, France)</option>
                    <option value="Africa/Cairo">UTC+02:00 (Cairo, Egypt)</option>
                    <option value="Europe/Moscow">UTC+03:00 (Moscow, Russia)</option>
                    <option value="Asia/Tehran">UTC+03:30 (Tehran, Iran)</option>
                    <option value="Asia/Dubai">UTC+04:00 (Dubai, United Arab Emirates)</option>
                    <option value="Asia/Kabul">UTC+04:30 (Kabul, Afghanistan)</option>
                    <option value="Asia/Karachi">UTC+05:00 (Karachi, Pakistan)</option>
                    <option value="Asia/Kolkata">UTC+05:30 (New Delhi, India)</option>
                    <option value="Asia/Kathmandu">UTC+05:45 (Kathmandu, Nepal)</option>
                    <option value="Asia/Dhaka">UTC+06:00 (Dhaka, Bangladesh)</option>
                    <option value="Asia/Rangoon">UTC+06:30 (Yangon, Myanmar)</option>
                    <option value="Asia/Bangkok">UTC+07:00 (Bangkok, Thailand)</option>
                    <option value="Asia/Shanghai">UTC+08:00 (Beijing, China)</option>
                    <option value="Australia/Eucla">UTC+08:45 (Eucla, Australia)</option>
                    <option value="Asia/Tokyo">UTC+09:00 (Tokyo, Japan)</option>
                    <option value="Australia/Adelaide">UTC+09:30 (Adelaide, Australia)</option>
                    <option value="Australia/Sydney">UTC+10:00 (Sydney, Australia)</option>
                    <option value="Australia/Lord_Howe">UTC+10:30 (Lord Howe Island, Australia)</option>
                    <option value="Pacific/Guadalcanal">UTC+11:00 (Honiara, Solomon Islands)</option>
                    <option value="Pacific/Auckland">UTC+12:00 (Auckland, New Zealand)</option>
                    <option value="Pacific/Chatham">UTC+12:45 (Chatham Islands, New Zealand)</option>
                    <option value="Pacific/Tongatapu">UTC+13:00 (Nuku'alofa, Tonga)</option>
                    <option value="Pacific/Kiritimati">UTC+14:00 (Kiritimati, Kiribati)</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="settings-team-member-alerts-container">
              <p className="settings-team-member-alerts-label">Team member alerts</p>

              <div className="settings-team-member-alerts-dropdowns-container">
                <div className="settings-team-member-alerts-email-dropdown-container">
                  <span className="settings-team-member-alerts-email-dropdown-label">Email alerts</span>
                  <select
                    className="settings-team-member-alerts-email-dropdown"
                    value={teamMemberAlertsEmailEnabled}
                    onChange={(e) => setTeamMemberAlertsEmailEnabled(e.target.value)}
                  >
                    <option value="true">Enabled</option>
                    <option value="false">Disabled</option>
                  </select>
                </div>
                <div className="settings-team-member-alerts-status-dropdown-container">
                  <span className="settings-team-member-alerts-status-dropdown-label">When status is</span>
                  <select
                    className="settings-team-member-alerts-status-dropdown"
                    value={memberAlertThreshold}
                    onChange={(e) => setMemberAlertThreshold(e.target.value)}
                  >
                    <option value="Offline">Offline</option>
                    <option value="Critical">Critical</option>
                    <option value="Poor">Poor</option>
                  </select>
                </div>

                <div className="settings-team-member-alerts-time-dropdown-container">
                  <span className="settings-team-member-alerts-time-dropdown-label">For more than</span>
                  <select className="settings-team-member-alerts-time-dropdown" value={memberAlertTime} onChange={(e) => setMemberAlertTime(e.target.value)}>
                    <option value="15">15 minutes</option>
                    <option value="30">30 minutes</option>
                    <option value="60">1 hour</option>
                    <option value="120">2 hours</option>
                    <option value="180">3 hours</option>
                    <option value="240">4 hours</option>
                  </select>
                </div>
              </div>

              <div className="settings-service-alerts-container">
                <p className="settings-service-alerts-label">Service alerts</p>

                <div className="settings-service-alerts-dropdowns-container">
                  <div className="settings-service-alerts-email-dropdown-container">
                    <span className="settings-service-alerts-email-dropdown-label">Email alerts</span>
                    <select
                      className="settings-service-alerts-email-dropdown"
                      value={serviceAlertsEmailEnabled}
                      onChange={(e) => setServiceAlertsEmailEnabled(e.target.value)}
                    >
                      <option value="true">Enabled</option>
                      <option value="false">Disabled</option>
                    </select>
                  </div>
                </div>
              </div>

              <button className="settings-alerts-save-button" onClick={updateTeamSettings}>
                Save
              </button>
            </div>
          </div>
        );
      case "Profile":
        return (
          <div>
            <div className="settings-profile-container">
              <div className="settings-profile-info-container">
                <p className="settings-profile-info-label">Profile Information</p>
                <div className="settings-profile-input-container">
                  <input
                    type="text"
                    className="settings-profile-input"
                    value={userFirstName}
                    onChange={(e) => setUserFirstName(e.target.value)}
                    placeholder={userFirstName}
                  />
                </div>
                <div className="settings-profile-input-container">
                  <input
                    type="text"
                    className="settings-profile-input"
                    value={userLastName}
                    onChange={(e) => setUserLastName(e.target.value)}
                    placeholder={userLastName}
                  />
                </div>
                <div className="settings-profile-input-container">
                  <input
                    type="text"
                    className="settings-profile-input"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    placeholder={userEmail}
                  />
                </div>
                <button className="settings-profile-info-update-button" onClick={updateUser}>
                  Update
                </button>
              </div>
              <div className="settings-profile-password-container">
                <p className="settings-profile-password-label">Change password</p>
                <div className="settings-profile-input-container">
                  <input
                    type="password"
                    className="settings-profile-input"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter new password"
                  />
                </div>
                <div className="settings-profile-input-container">
                  <input
                    type="password"
                    className="settings-profile-input"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Re-enter new password"
                  />
                </div>
                <button className="settings-profile-password-corfirm-button" onClick={updatePassword}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        );
      case "Account":
        return (
          <div>
            <div className="settings-account-container">
              <div className="settings-account-license-container">
                <p className="settings-account-license-type-label">Role</p>
                <div className="settings-account-license-type-value-container">
                  <LicenseTypeIconSvg />
                  {userAccountInfo && (
                    <p className="settings-account-license-type-value">{userAccountInfo.IsUserTeamAdmin ? "Administrator" : "Team member"}</p>
                  )}
                </div>
                <p className="settings-account-license-type-label">Plan</p>
                <div className="settings-account-license-type-value-container">
                  {userAccountInfo && (
                    <p className="settings-account-license-type-value">
                      {userAccountInfo.UserStripePlan === null || userAccountInfo.UserStripePlan === "cancelled"
                        ? "Free Plan"
                        : userAccountInfo.UserStripePlan === "price_1OhzJQKWXnF715LijtZmlLl8"
                        ? "Starter Plan"
                        : userAccountInfo.UserStripePlan === "price_1OhZkIKWXnF715Li91LqajrP"
                        ? "Professional Plan"
                        : userAccountInfo.UserStripePlan === "price_1OhZrMKWXnF715LitiDpEsKV"
                        ? "Business Plan"
                        : "Unknown Plan"}
                    </p>
                  )}
                </div>
                <div className="settings-account-update-buttons-container">
                  <button className="settings-account-update-plan-button" onClick={handleUpdatePlanClick}>
                    Update Plan
                  </button>
                  {userAccountInfo && userAccountInfo.IsUserCustomer && (
                    <button
                      className="settings-account-update-payment-button"
                      onClick={() => window.open("https://billing.stripe.com/p/login/test_14keXN5wReqL2D6cMM", "_blank")}
                    >
                      <UpdatePaymentIconSvg />
                      Update Payment Details
                    </button>
                  )}
                </div>
              </div>
              <div className="settings-account-usage-container">
                <div className="settings-account-usage-monitors-container">
                  <div className="settings-account-usage-monitors-info-container">
                    <p className="settings-account-usage-monitors-label">Monitors:</p>
                    <div className="settings-account-usage-monitors-values-container">
                      {userAccountInfo && <p className="settings-account-usage-monitors-used-value">{userAccountInfo.UserMonitorsUsed}</p>}
                      <p className="settings-account-usage-monitors-used-label">Used</p>
                      <p className="settings-account-usage-monitors-vinculum">/</p>
                      {userAccountInfo && <p className="settings-account-usage-monitors-total-value">{userAccountInfo.UserMonitorsTotal}</p>}
                      <p className="settings-account-usage-monitors-total-label">Total</p>
                    </div>
                  </div>
                </div>

                <div className="settings-account-usage-team-container">
                  <div className="settings-account-usage-team-info-container">
                    <p className="settings-account-usage-team-label">Team members:</p>
                    {userAccountInfo && <p className="settings-account-usage-team-used-value">{userAccountInfo.TeamMemberCount}</p>}
                  </div>
                  <button className="settings-account-usage-team-add-button">Manage</button>
                </div>

                <div className="settings-account-usage-services-container">
                  <div className="settings-account-usage-services-info-container">
                    <p className="settings-account-usage-services-label">Services:</p>
                    {userAccountInfo && <p className="settings-account-usage-services-used-value">{userAccountInfo.TeamServicesCount}</p>}
                  </div>
                  <button className="settings-account-usage-services-add-button">Manage</button>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return <div>Content for Alerts</div>;
    }
  };

  return (
    <div>
      <div className="settings-container">
        <div className="settings-tab-buttons-container">
          <button className={getButtonClass("Alerts")} onClick={() => setSelectedTab("Alerts")}>
            Alerts
          </button>
          <button className={getButtonClass("Profile")} onClick={() => setSelectedTab("Profile")}>
            Profile
          </button>
          <button className={getButtonClass("Account")} onClick={() => setSelectedTab("Account")}>
            Account
          </button>
        </div>
        <div className="settings-tab-content">{renderContent()}</div>
      </div>
    </div>
  );
};

export default Settings;
