import React, { useState } from "react";
import "./Downloads.css"; // Import the CSS file

const Downloads = () => {
  // Retrieve the activation code from local storage
  const activationCode = localStorage.getItem("activationCode") || "Not available";
  const [displayText, setDisplayText] = useState(activationCode);

  const handleActivationCodeClick = () => {
    navigator.clipboard.writeText(activationCode)
      .then(() => {
        setDisplayText('Copied');

        setTimeout(() => {
          setDisplayText(activationCode);
        }, 3000);
      })
      .catch(err => {
        console.error('Failed to copy activation code: ', err);
        // Handle any errors (e.g., clipboard permissions denied)
      });
  };

  return (
    <div className="downloads-container">
      {/* Left Column */}
      <div className="downloads-container-left-column">
        <p className="downloads-container-left-column-title">SignalTuner desktop client</p>
        <p className="downloads-container-left-column-CTA">Download, install, and enter your activation code to synch with your team</p>
        <a href="https://signaltuner.com/update/counter.php" target="_blank" rel="noopener noreferrer">
          <button className="downloads-container-left-column-button" type="button">
            Download
          </button>
        </a>
        <p className="downloads-container-left-column-activation-label">Your activation code:</p>
        <p className="downloads-container-left-column-activation-code"onClick={handleActivationCodeClick}>
          <span className="activation-code-inner-text">{displayText}</span>
        </p>
      </div>

      {/* Right Column */}
      <div className="downloads-container-right-column">
        <img src="https://www.signaltuner.com/images/wifisignal.png" alt="WiFi Signal" />
      </div>
    </div>
  );
};

export default Downloads;
