import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isLoggedIn, children }) => {
  if (!isLoggedIn) {
    // Redirect to the Home component if not logged in
    return <Navigate to="/home" />;
  }

  return children;
}

export default ProtectedRoute;