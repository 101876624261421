import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Services.css";
import config from "./config";
import {
  RemoveServiceButtonSvg,
  ServiceDetailsButtonIconSvg,
  ServiceDetailsButtonArrowSvg,
  MonitorButtonArrowSvg,
  NoIssueStatusIconSvg,
  IncidentStatusIconSvg,
  OutageStatusIconSvg,
} from "./images/ServicesSVG";

const Services = ({ refreshTeamServiceSelections, removeServiceFromTeam, teamServices, refreshUserAccountInfo, userAccountInfo }) => {
  const [services, setServices] = useState([]); // Local state to store services
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Retrieve query parameters from the URL using useLocation
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialServiceName = queryParams.get("serviceName");
  const initialDescription = queryParams.get("description");

  useEffect(() => {
    refreshUserAccountInfo();
  }, [refreshUserAccountInfo]);

  const [modalContent, setModalContent] = useState({
    name: initialServiceName || "",
    description: initialDescription || "",
  });
  const [isModalOpen, setIsModalOpen] = useState(Boolean(initialServiceName) || Boolean(initialDescription));

  const getStatusStyle = (status) => {
    switch (status) {
      case "No issues":
        return { color: "#3BB537" };
      case "Incident":
        return { color: "#F9AE00" };
      case "Outage":
        return { color: "#EC2F3E" };
      default:
        return {};
    }
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case "No issues":
        return (
          <div className="status-icon" style={{ background: "#3BB537", width: "18px", height: "18px" }}>
            <NoIssueStatusIconSvg />
          </div>
        );
      case "Incident":
        return (
          <div className="status-icon" style={{ background: "#F9AE00", width: "18px", height: "18px" }}>
            <IncidentStatusIconSvg />
          </div>
        );
      case "Outage":
        return (
          <div className="status-icon" style={{ background: "#EC2F3E", width: "18px", height: "18px" }}>
            <OutageStatusIconSvg />
          </div>
        );
      default:
        return null;
    }
  };

  const Modal = ({ isOpen, onClose, content }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2 style={{ fontWeight: "bold", textDecoration: "underline" }}>{content.name}</h2>
          <p style={{ fontWeight: "bold", textDecoration: "underline" }}>Most recent incident</p>
          <div dangerouslySetInnerHTML={{ __html: content.description }} />
          <button className="modal-close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  };

  const navigate = useNavigate();

  const handleOpenModal = (serviceName, description) => {
    setModalContent({ name: serviceName, description: description });
    setIsModalOpen(true);

    // Clear query parameters from the URL
    navigate("/services", { replace: true });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // Clear query parameters from the URL
    navigate("/services", { replace: true });
  };

  const API_URL = config.API_URL;

  useEffect(() => {
    const fetchTeamServiceSelections = async () => {
      try {
        setLoading(true);
        const userID = localStorage.getItem("userID");
        const token = localStorage.getItem("token");

        const response = await axios.get(`${API_URL}api/User/GetAllServicesWithSelectionStatus/${userID}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setServices(teamServices);
        setError("");
      } catch (err) {
        setError("Failed to fetch service selections");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamServiceSelections();
  }, [teamServices]);

  const handleAddServiceToTeam = async (serviceId) => {
    try {
      const userID = localStorage.getItem("userID");
      const token = localStorage.getItem("token");
      const teamId = localStorage.getItem("userTeamID");

      if (userAccountInfo.UserMonitorsTotal <= userAccountInfo.UserMonitorsUsed) {
        if (userAccountInfo.IsUserCustomer && userAccountInfo.UserStripePlan && userAccountInfo.UserStripePlan !== "cancelled") {
          // If the user is a customer, open the Stripe billing page in a new tab
          window.open("https://billing.stripe.com/p/login/test_14keXN5wReqL2D6cMM", "_blank");
        } else {
          // If the user is not a customer, navigate to the "Plans" page within the same tab
          navigate("/plans"); // Replace '/plans' with the actual path to your Plans page
        }
      } else {
        await axios.post(
          `${API_URL}api/User/AddTeamServiceSelection`,
          {
            UserId: userID,
            TeamId: teamId,
            ServiceStatusId: serviceId,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        refreshTeamServiceSelections();
        refreshUserAccountInfo();
      }
    } catch (err) {
      console.error("Error adding service to team:", err);
      // Handle error
    }
  };

  const handleRemoveServiceFromTeam = async (serviceId) => {
    try {
      await removeServiceFromTeam(serviceId);
      await refreshTeamServiceSelections();
      await refreshUserAccountInfo();
    } catch (err) {
      console.error("Error removing  service from team:", err);
      // Handle error
    }
  };

  // Filter services based on selection status
  const selectedServices = services.filter((service) => service.IsSelected);
  const nonSelectedServices = services.filter((service) => !service.IsSelected);

  return (
    <div>
      {isModalOpen && <Modal isOpen={isModalOpen} onClose={handleCloseModal} content={modalContent} />}

      <div className="services-container">
        <h2>Monitored</h2>
        {selectedServices ? <h3>Monitored services: {selectedServices.length} </h3> : <h3>Loading services data...</h3>}
        {userAccountInfo ? (
          <h3>Available monitors: {userAccountInfo.UserMonitorsTotal - userAccountInfo.UserMonitorsUsed}</h3>
        ) : (
          <h3>Loading services data...</h3>
        )}

        <div className="services-grid">
          {selectedServices.map((service, index) => (
            <div className="service-card" key={index}>
              <button className="remove-service-button" onClick={() => handleRemoveServiceFromTeam(service.SsId)}>
                <RemoveServiceButtonSvg />
              </button>
              <div className="service-container">
                <img
                  className="service-icon"
                  src={`https://www.signaltuner.com/images/serviceicons/${service.SsServiceName}.png`}
                  alt={`${service.SsServiceName} Icon`}
                />
                <div className="service-name">{service.SsServiceName}</div>
                <div className="status-details-container">
                  <div className="status-content">
                    {renderStatusIcon(service.SsCurrent)}
                    <div className="service-status" style={getStatusStyle(service.SsCurrent)}>
                      {service.SsCurrent}
                    </div>
                    <button className="details-button" onClick={() => handleOpenModal(service.SsServiceName, service.SsDescription)}>
                      <ServiceDetailsButtonIconSvg />
                      <ServiceDetailsButtonArrowSvg />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="services-container">
        <h2>Available Services</h2>
        <div className="services-grid">
          {nonSelectedServices.map((service, index) => (
            <div className="service-card" key={index}>
              <div className="service-container">
                <img
                  className="service-icon"
                  src={`https://www.signaltuner.com/images/serviceicons/${service.SsServiceName}.png`}
                  alt={`${service.SsServiceName} Icon`}
                />
                <div className="service-name">{service.SsServiceName}</div>
                {!service.IsSelected && (
                  <div className="monitor-button-div">
                    <button className="monitor-button" onClick={() => handleAddServiceToTeam(service.SsId)}>
                      <MonitorButtonArrowSvg />
                      Monitor
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
